import { useTranslation } from 'react-i18next';

import asset from '@assets/images/noAccess.svg';
import { namespaces } from '@shared/constants';

const NoAccessPage = () => {
    const { t } = useTranslation(namespaces.common);
    return (
        <div className="flex items-center justify-center pt-[143px]">
            <div className="flex max-w-[453px] flex-col gap-6">
                <p className="text-6xl font-bold">{t('noAccessPage.title')}</p>
                <p className="text-xl font-normal leading-8">
                    {t('noAccessPage.description')}
                    <span className="font-bold">{t('noAccessPage.boldDescription')}</span>
                </p>
            </div>
            <div>
                <img src={asset} alt="no access image" />
            </div>
        </div>
    );
};

export default NoAccessPage;
