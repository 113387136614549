import { Fragment, useEffect, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { useResizeMediaQuery } from '../useResizeMediaQuery';

import { getMenuItems } from './domain';
import { getNavItems } from './getNavItems';
import classes from './navigation.module.scss';

import { ReactComponent as ChatBubble } from '@assets/icons/chatBubble.svg';
import { ReactComponent as ChevronDown } from '@assets/icons/chevronDown.svg';
import { ReactComponent as HomeIcon } from '@assets/icons/home.svg';
import { Avatar } from '@features/authentication';
import { checkPermission, useMeInformationQuery } from '@features/authorization';
import { useMainMenuQuery } from '@shared/CMS';
import { blurActiveElementIn } from '@shared/generic-functions';
import { Link, Dropdown } from '@shared/ui';
import { useStore } from '@store';

const Navigation = () => {
    const account = useStore(state => state.auth.account);
    const { pathname } = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const { data: meInformation } = useMeInformationQuery();
    const { current: menuItems } = useRef(getMenuItems());

    const { data: mainMenuData } = useMainMenuQuery();
    const menuListRef = useRef(null);
    const { t } = useTranslation();

    const { isMobile } = useResizeMediaQuery();

    const [isChevronRotated, setIsChevronRotated] = useState<boolean>(false);
    const baseSpanClass = 'absolute block h-0.5 w-6 transform bg-gray-500 transition duration-300 ease-in-out';
    const centerTransform = 'top-1/2 -translate-y-1/2';

    const handleClick = () => {
        if (isMobile) {
            setIsChevronRotated(prev => !prev);
        }
    };

    const closeMenu = () => {
        setIsOpen(false);
        setIsChevronRotated(false);
    };

    useEffect(() => {
        const body = document.body;
        const mobileOpenClass = 'mobile-menu-open';
        if (!isMobile || !isOpen) {
            body.classList.remove(mobileOpenClass);
        } else {
            body.classList.add(mobileOpenClass);
        }
    }, [isOpen, isMobile]);

    const navItems = useMemo(() => {
        return getNavItems(mainMenuData);
    }, [mainMenuData]);

    function isActiveItem(children: any, pathname: string, hasChildren: boolean, url: string): boolean {
        if (hasChildren) {
            return !!children.find(({ links }: any) => !!links.find(({ url }: any) => url === pathname));
        } else {
            return pathname === url;
        }
    }

    const filteredMenuItems = useMemo(() => {
        return menuItems.filter(menuItem =>
            checkPermission(meInformation?.permissions || [], menuItem.permission ? [menuItem.permission] : [])
        );
    }, [meInformation, navItems]);

    return (
        <nav data-testid="header-navigation">
            {isMobile ? (
                <button
                    className="relative z-30 h-12 w-12 bg-white text-gray-500 focus:outline-none"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <span className="sr-only">{isOpen ? 'Close menu' : 'Open menu'}</span>
                    <div className="absolute left-1/2 top-1/2 block h-6 w-6 -translate-x-1/2 -translate-y-1/2 transform">
                        <span
                            aria-hidden="true"
                            className={`${baseSpanClass} ${isOpen ? 'rotate-45' : 'top-1/4'} ${
                                isOpen ? centerTransform : ''
                            }`}
                        ></span>
                        <span
                            aria-hidden="true"
                            className={`${baseSpanClass} ${isOpen ? 'opacity-0 ' + centerTransform : 'top-1/2'}`}
                        ></span>
                        <span
                            aria-hidden="true"
                            className={`${baseSpanClass} ${isOpen ? '-rotate-45 ' + centerTransform : 'top-3/4'}`}
                        ></span>
                    </div>
                </button>
            ) : null}

            <CSSTransition
                in={!isMobile || isOpen}
                nodeRef={menuListRef}
                timeout={300}
                unmountOnExit
                classNames={{
                    enter: classes.enter,
                    enterActive: classes.enterActive,
                    exit: classes.exit,
                    exitActive: classes.exitActive,
                }}
            >
                <div
                    ref={menuListRef}
                    className={classNames(
                        'fixed inset-0 z-10 overflow-auto bg-white pt-header-height',
                        'md:relative md:items-center md:overflow-visible md:pt-0',
                        'flex flex-col pl-8 lg:flex-row'
                    )}
                >
                    {isMobile && (
                        <ul className="mr-[40px] mt-6 lg:mt-0" role="presentation">
                            {filteredMenuItems.map(({ dataTestId, name, to }) => (
                                <li key={to}>
                                    <Link
                                        dataTestId={dataTestId}
                                        to={to}
                                        role="menuitem"
                                        className="hover:text-blue-850 lg:text-blue-850"
                                        onClick={() => {
                                            closeMenu();
                                        }}
                                    >
                                        {t(name)}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    )}

                    <ul
                        className={classNames(
                            'text-left sm:text-center',
                            'justify-start sm:justify-center',
                            'md:mr-1 md:flex md:flex-wrap md:items-center md:justify-end md:text-left',
                            'flex-grow'
                        )}
                    >
                        <li className="mr-[40px] hidden sm:block" role="presentation">
                            <Link
                                dataTestId="homeLink"
                                to="/#"
                                role="menuitem"
                                className="[&_>svg]:ml-0 [&_>svg]:h-6 [&_>svg]:w-6"
                            >
                                <HomeIcon style={{ width: '24px', height: '24px', margin: 0 }} />
                            </Link>
                        </li>

                        {navItems.map(({ url, name, id, disabled, children, testid }, i) => {
                            const hasChildren = Boolean(children?.length ?? false);
                            const to = url ?? '';
                            const active = isActiveItem(children, pathname, hasChildren, to);

                            const safeChildren = children ?? [];

                            return (
                                <li key={i} className="mr-[40px] lg:mt-0" role="presentation">
                                    {hasChildren ? (
                                        <Dropdown
                                            title={
                                                <Link
                                                    type="button"
                                                    id={id}
                                                    dataTestId={testid}
                                                    active={active}
                                                    to={to}
                                                    className="group-hover:underline [&_svg]:transition-transform [&_svg]:group-hover:[transform:rotateX(180deg)]"
                                                    onClick={handleClick}
                                                >
                                                    {name}
                                                    {isMobile ? (
                                                        <ChevronDown
                                                            style={{
                                                                transform: isChevronRotated
                                                                    ? 'rotateX(180deg)'
                                                                    : 'none',
                                                            }}
                                                        />
                                                    ) : (
                                                        <ChevronDown />
                                                    )}
                                                </Link>
                                            }
                                            dropdownClassNames="w-full min-w-[140px] lg:pt-4 pt-0 pl-4 lg:pl-0 right-0 top-full  sm:absolute "
                                        >
                                            <div
                                                className={classNames(
                                                    'bg-white text-xs sm:z-10 sm:shadow-[1px_1px_6px_0px] sm:shadow-gray-800/25',
                                                    '[&_a]:block [&_a]:p-2',
                                                    '[&_a]:pl-6',
                                                    'hover:[&_a]:bg-blue-850 hover:[&_a]:text-white hover:[&_a]:no-underline',
                                                    'w-full sm:w-auto'
                                                )}
                                            >
                                                {safeChildren.map(({ links, title }, i) => {
                                                    return (
                                                        <div key={i}>
                                                            {links.map(
                                                                ({ id, name, disabled, testid, url }, linkIndex) => {
                                                                    const active = pathname === url;
                                                                    const to = url ?? '';

                                                                    return (
                                                                        <Fragment key={id}>
                                                                            {linkIndex === 0 && (
                                                                                <span
                                                                                    className={classNames(
                                                                                        'inline-block p-2 lg:text-black',
                                                                                        {
                                                                                            'text-zinc-400': disabled,
                                                                                        }
                                                                                    )}
                                                                                >
                                                                                    {title}
                                                                                </span>
                                                                            )}

                                                                            {!disabled ||
                                                                            (disabled && window.innerWidth > 640) ? (
                                                                                <Link
                                                                                    id={id}
                                                                                    active={active}
                                                                                    to={to}
                                                                                    disabled={disabled}
                                                                                    dataTestId={testid}
                                                                                    onClick={() => {
                                                                                        blurActiveElementIn(document);
                                                                                        closeMenu();
                                                                                    }}
                                                                                    className={classNames(
                                                                                        'group/item',
                                                                                        { 'sm:hidden': disabled },
                                                                                        { '!bg-gray-100': disabled }
                                                                                    )}
                                                                                >
                                                                                    <span
                                                                                        className={classNames(
                                                                                            'relative bottom-1 mr-[6px] inline-block h-[11px] w-[11px] rounded-bl border-l border-b',
                                                                                            'group-hover/item:border-white',
                                                                                            {
                                                                                                '!bg-gray-100 sm:hidden':
                                                                                                    disabled,
                                                                                            }
                                                                                        )}
                                                                                    />
                                                                                    {name}
                                                                                </Link>
                                                                            ) : null}
                                                                        </Fragment>
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </Dropdown>
                                    ) : (
                                        <Link
                                            id={id}
                                            to={to}
                                            disabled={disabled}
                                            active={active}
                                            className=" hover:text-blue-850 lg:text-blue-850"
                                            onClick={closeMenu}
                                        >
                                            {name}
                                        </Link>
                                    )}
                                </li>
                            );
                        })}
                        {filteredMenuItems.map(({ dataTestId, name, to }) => (
                            <li key={to} className="mr-[40px] hidden sm:block">
                                <Link
                                    dataTestId={dataTestId}
                                    to={to}
                                    role="menuitem"
                                    className="hover:text-blue-850 lg:text-blue-850"
                                >
                                    {t(name)}
                                </Link>
                            </li>
                        ))}
                    </ul>

                    <ul className="mb-12 flex items-center space-x-4 text-left sm:text-center md:text-left lg:mb-0">
                        <li data-testid="user-avatar">
                            <Avatar photo={meInformation?.photo ?? undefined} name={account?.name ?? ''} />
                        </li>
                        <li data-testid="external-link-button">
                            <button
                                className="flex h-9 w-9 items-center justify-center rounded-full bg-basic-blue hover:bg-primary-blue-75 focus:outline-none active:bg-primary-blue-dark"
                                onClick={() =>
                                    window.open(
                                        'https://nestle.service-now.com/itsp?id=nesa_itsp_sc_cat_item&sys_id=e50643dcc36b46506f2165beb00131cc',
                                        '_blank'
                                    )
                                }
                                aria-label="Chat support"
                            >
                                <ChatBubble />
                            </button>
                        </li>
                    </ul>
                </div>
            </CSSTransition>
        </nav>
    );
};

export { Navigation };
