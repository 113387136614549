import { useMemo, useCallback } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Spinner } from '../spinner';

import { ReactComponent as ApproveIcon } from '@assets/icons/approveAction.svg';
import { ReactComponent as CancelIcon } from '@assets/icons/cancelAction.svg';
import { ReactComponent as EditIcon } from '@assets/icons/editAction.svg';
import { ReactComponent as RejectIcon } from '@assets/icons/rejectAction.svg';
import { namespaces } from '@shared/constants';

export enum ActionButtomType {
    Approve = 'approve',
    Reject = 'reject',
    Cancel = 'cancel',
    Request = 'request',
}

type ActionButtonProps = {
    onClickAction: () => void | Promise<void>;
    state?: 'idle' | 'loading';
    type: `${ActionButtomType}`;
    iconPosition?: 'left' | 'right';
};

const ActionButton = ({ onClickAction, state, type, iconPosition = 'left' }: ActionButtonProps) => {
    const { t } = useTranslation(namespaces.features.coInnovationFunds);

    const buttonConfig = useMemo(
        () => ({
            approve: {
                textKey: 'coInnovationFunds.validationFundsForm.approve',
                icon: ApproveIcon,
                className: 'bg-primary-blue-100',
            },
            reject: {
                textKey: 'coInnovationFunds.validationFundsForm.reject',
                icon: RejectIcon,
                className: 'bg-secondary-purple',
            },
            cancel: {
                textKey: 'coInnovationFunds.validationFundsForm.cancel',
                icon: CancelIcon,
                className: 'bg-secondary-purple',
            },
            request: {
                textKey: 'coInnovationFunds.validationFundsForm.requestChange',
                icon: EditIcon,
                className: 'bg-primary-blue-100',
            },
        }),
        []
    );

    const handleClick = useCallback(() => {
        onClickAction();
    }, [onClickAction]);

    const { textKey, icon: Icon, className } = buttonConfig[type] || {};

    return (
        <button
            onClick={handleClick}
            disabled={state === 'idle'}
            className={classNames(
                'flex w-[160px] items-center justify-center rounded fill-current p-3 text-center text-white',
                className,
                { 'opacity-60': state === 'idle' }
            )}
        >
            {state === 'loading' && <Spinner size={2} />}
            {(iconPosition !== 'right' || iconPosition === undefined) && Icon && (
                <Icon className="fill-current text-white" />
            )}
            <span className="body-m-book whitespace-nowrap p-1">{t(textKey)}</span>
            {iconPosition === 'right' && Icon && <Icon className="fill-current text-white" />}
        </button>
    );
};

export default ActionButton;
