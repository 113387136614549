import { useEffect } from 'react';

import { Parallax } from 'react-parallax';
import { useLocation } from 'react-router-dom';

import LandingPage from './LandingPage';

import { HasPermission, permissions } from '@features/authorization';
import { Discovery } from '@features/discovery';
import { Initiatives } from '@features/initiatives/list/Initiatives';
import PartnersLanding from '@features/landings/partnersLanding/PartnersLanding';

const PreLandingPage = () => {
    const location = useLocation();

    const params = new URLSearchParams(location.search);
    const pageParam = params.get('page') as 'discovery' | 'initiatives';

    useEffect(() => {
        const hash = location.hash;
        const observer = new MutationObserver(() => {
            const section = document.getElementById('section');
            if (section && hash) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        });

        observer.observe(document.body, { childList: true, subtree: true });

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <Parallax>
            <HasPermission neededPermissions={[permissions.nestleAccount.view]}>
                <div className="scroll-smooth">
                    <section id="preLanding" className="relative h-screen items-center justify-center">
                        <LandingPage section={pageParam} />
                    </section>

                    <section id="section" className="relative h-screen items-center justify-center bg-white">
                        <div className="relative h-full w-full">
                            <div className={pageParam === 'discovery' || !pageParam ? 'block' : 'hidden'}>
                                <Discovery />
                            </div>

                            <div className={pageParam === 'initiatives' ? 'block' : 'hidden'}>
                                <Initiatives />
                            </div>
                        </div>
                    </section>
                </div>
            </HasPermission>

            <HasPermission neededPermissions={[permissions.partner.view]}>
                <PartnersLanding />
            </HasPermission>
        </Parallax>
    );
};

export default PreLandingPage;
