import { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { PageType } from '../shared';
import { ChangeRequestValidationDialog } from '../summary/funds/ChangeRequestValidationDialog';
import { FundValidationDialog } from '../summary/funds/FundValidationDialog';

import { SortParams } from './domain';
import { FundChangeRequestDialog } from './FundChangeRequestDialog';
import { FundFilterDialog } from './FundFilterDialog';
import { FundKpis } from './FundKpis';
import { FundTable } from './FundTable';
import { useGetFundsQuery } from './useGetFundsQuery';

import { HasPermission } from '@features/authorization/HasPermission';
import { permissions } from '@features/authorization/permissions';
import { namespaces } from '@shared/constants';
import { defaultPageSize } from '@shared/constants/paging';
import { formatNumber } from '@shared/formatting';
import { usePagination, usePaginationTotals } from '@shared/ui/pagination/usePagination';
import { useStore } from '@store';

export type DialogTypes = 'cancel' | 'approve' | 'reject' | 'changeRequest' | 'approveCR' | 'rejectCR' | 'cancelCR';

const canValidateFundPermissions = [permissions.coInnovationFunds.fundsManagement.validation];

const canChangeRequestPermissions = [
    permissions.coInnovationFunds.partnerRequests.create,
    permissions.coInnovationFunds.allRequests.create,
];

const canValidateChangeRequestPermissions = [permissions.coInnovationFunds.fundsManagement.validation];

export type SelectedFundState = {
    id: string;
    types: DialogTypes[];
};

export const Funds = () => {
    const [sort, setsort] = useState<SortParams>({
        sorting: { id: '', desc: false },
    });

    const { setPage } = useStore(state => state.coInnovationFunds);
    const {
        pagination: { pageIndex, pageSize },
        setPagination,
        firstItemIndex,
    } = usePagination({ pageSize: defaultPageSize });

    const { data: funds, isFetching } = useGetFundsQuery({
        pageIndex,
        pageSize,
        sort,
        paginationReset: () => {
            setPagination(prevState => ({ pageIndex: 0, pageSize: prevState.pageSize }));
        },
    });

    const { pageCount, lastItemIndex } = usePaginationTotals({
        totalItems: funds?.total || 0,
        pagination: { pageIndex, pageSize },
    });

    const [selectedFundState, setSelectedFundState] = useState<SelectedFundState>();
    const selectedFund = funds?.items.find(({ id }) => id === selectedFundState?.id);
    const { t } = useTranslation(namespaces.features.coInnovationFunds);

    const totalProvided = formatNumber(funds?.totalProvided ?? 0);
    const totalAvailable = formatNumber(funds?.totalAvailable ?? 0);
    const availablePercentage = formatNumber(
        ((funds?.totalAvailable ?? 0) / (funds?.totalProvided === 0 ? 1 : funds?.totalProvided ?? 1)) * 100
    );
    const totalExpired = formatNumber(funds?.totalExpired ?? 0);
    const expiredPercentage = formatNumber(
        ((funds?.totalExpired ?? 0) / (funds?.totalProvided === 0 ? 1 : funds?.totalProvided ?? 1)) * 100
    );

    useEffect(() => {
        setPage(PageType.Fund);
    }, []);

    const onFundValidationClose = useCallback(
        () =>
            setSelectedFundState(previousState => ({
                id: selectedFund?.id || '',
                types:
                    previousState?.types?.filter(
                        type => type !== 'approve' && type !== 'reject' && type !== 'changeRequest'
                    ) || [],
            })),
        [setSelectedFundState, selectedFund?.id]
    );

    const onCRValidationClose = useCallback(
        () =>
            setSelectedFundState(previousState => ({
                id: selectedFund?.id || '',
                types: previousState?.types?.filter(type => type !== 'approveCR' && type !== 'rejectCR') || [],
            })),
        [setSelectedFundState, selectedFund?.id]
    );

    const sections = [
        {
            title: t('coInnovationFunds.fundsManagement.totalProvided'),
            value: `${totalProvided} CHF`,
            titleTestId: 'totalProvided-title',
            valueTestId: 'totalProvided-value',
        },
        {
            title: t('coInnovationFunds.fundsManagement.totalAvailable'),
            value: `${totalAvailable} CHF`,
            subValue: `${availablePercentage}% ${t('coInnovationFunds.summary.allocated.allocatedPercentage')}`,
            titleTestId: 'totalAllocated-title',
            valueTestId: 'totalAllocated-value',
        },
        {
            title: t('coInnovationFunds.fundsManagement.totalExpired'),
            value: `${totalExpired} CHF`,
            subValue: `${expiredPercentage}% ${t('coInnovationFunds.summary.expired.expiredPercentage')}`,
            titleTestId: 'totalExpired-title',
            valueTestId: 'totalExpired-value',
        },
    ];

    return (
        <div className="w-full px-6 md:px-8">
            <div className="pt-2">
                <FundKpis sections={sections} />
            </div>
            <div className="flex flex-grow flex-row">
                <FundTable
                    funds={funds}
                    isFetching={isFetching}
                    sort={sort}
                    setSort={setsort}
                    setSelectedFundState={setSelectedFundState}
                    setPagination={setPagination}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    firstItemIndex={firstItemIndex}
                    lastItemIndex={lastItemIndex}
                    pageCount={pageCount}
                />

                <FundFilterDialog />

                {!!selectedFundState?.types.find(type => ['approve', 'reject'].includes(type)) && (
                    <HasPermission neededPermissions={canValidateFundPermissions}>
                        <FundValidationDialog
                            isOpen={!!selectedFundState?.types.find(type => ['approve', 'reject'].includes(type))}
                            id={selectedFundState.id}
                            modalType={selectedFundState?.types.includes('approve') ? 'approve' : 'reject'}
                            onClose={onFundValidationClose}
                        />
                    </HasPermission>
                )}

                {selectedFundState?.types.includes('changeRequest') && (
                    <HasPermission neededPermissions={canChangeRequestPermissions}>
                        <FundChangeRequestDialog
                            setSelectedFundState={setSelectedFundState}
                            selectedFund={selectedFund}
                        />
                    </HasPermission>
                )}

                {!!selectedFundState?.types.find(type => ['approveCR', 'rejectCR'].includes(type)) && (
                    <HasPermission neededPermissions={canValidateChangeRequestPermissions}>
                        <ChangeRequestValidationDialog
                            isOpen={!!selectedFundState?.types.find(type => ['approveCR', 'rejectCR'].includes(type))}
                            id={selectedFundState.id}
                            modalType={selectedFundState?.types.includes('approveCR') ? 'approve' : 'reject'}
                            onClose={onCRValidationClose}
                            selectedFund={selectedFund}
                        />
                    </HasPermission>
                )}
            </div>
        </div>
    );
};
