import { useQuery } from '@tanstack/react-query';

import { getActiveCrByFundId } from '../api';
import { changeRequestParser } from '../domain';

import { retrieveFromCache, saveToCache } from '@shared/caching';

const queryKey = 'crfund';

export const useGetFundChangeRequestById = (id: string) => {
    return useQuery([queryKey, id], async () => getActiveCrByFundId(id), {
        onSuccess: data => saveToCache(`${queryKey}-${id}`, data),
        placeholderData: () => retrieveFromCache(`${queryKey}-${id}`, changeRequestParser.parse),
    });
};
