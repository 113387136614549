import { PropsWithChildren } from 'react';

import { ColumnSort } from '@tanstack/react-table';
import classNames from 'classnames';

import { Sorting } from '../../../../sorting';
import { CustomColumn } from '../../domain';
import { CellAlign } from '../CellTypes';

import { InfoTooltip } from '@shared/ui/tooltip';

type Props<T extends object> = {
    id: string;
    columns: CustomColumn<T>[];
    sortingCallback: (sorting: ColumnSort) => void;
    sorting?: ColumnSort;
    width?: number;
};

const HeaderCell = <T extends object>({
    id,
    children,
    columns,
    sortingCallback,
    sorting,
    width,
}: PropsWithChildren<Props<T>>) => {
    const column = columns.find(f => f.accessorKey === id);

    if (!column) {
        return (
            <th key={id} style={{ width: width ? `${width}%` : undefined }} data-testid="th-header-cell">
                {children}
            </th>
        );
    }

    const cellAlign = column.cellType ? CellAlign[column.cellType] : 'left';

    return (
        <th key={id} style={{ width: width ? `${width}%` : undefined }} data-testid="th-header-cell">
            {column.sortable ? (
                <Sorting sortingCallback={sortingCallback} sorting={sorting} id={id} key={id} alignContent={cellAlign}>
                    {column.tooltip && column.header ? (
                        <InfoTooltip placement={'top'} id={column.header} title={undefined}>
                            {column.tooltip}
                        </InfoTooltip>
                    ) : null}
                    <div className="ml-2 inline-block w-fit">{children}</div>
                </Sorting>
            ) : (
                <div
                    className={classNames('flex items-center', {
                        'justify-between': cellAlign === 'left',
                        'text-center': cellAlign === 'right',
                    })}
                >
                    {column.tooltip ? (
                        <InfoTooltip placement={'top'} id={`${id}-tooltip`} title={undefined}>
                            {column.tooltip}
                        </InfoTooltip>
                    ) : null}
                    <span className="ml-2">{children}</span>
                </div>
            )}
        </th>
    );
};

export { HeaderCell };
