import classNames from 'classnames';

import userSvgUrl from '@assets/icons/user.svg';

type Props = {
    name: string;
    photo?: string | null;
};

export const Contact = ({ name, photo }: Props) => {
    return (
        <div className="inline-flex items-center font-normal">
            <div
                className={classNames('mr-2 inline-block h-8 w-8 rounded-full border border-blue-800', {
                    'p-[6px]': !photo,
                })}
            >
                <img
                    className={classNames('mb-4 h-full w-full object-contain', {
                        'rounded-full': photo,
                    })}
                    src={photo ? `data:image/jpg;base64,${photo}` : userSvgUrl}
                    alt=""
                />
            </div>

            <span>{name}</span>
        </div>
    );
};
