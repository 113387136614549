import { useState, useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { GetInitiativesFilterParam } from '../domain';
import { InitiativeReport, InitiativesFilterType } from '../types';
import { useGetInitiatives } from '../useGetInitiatives';

import { InitiativesHeader } from './header/InitiativesHeader';
import { InitiativesFilterContent } from './InitiativesFilterContent';
import { InitiativesReports } from './InitiativesReports';

import { namespaces } from '@shared/constants';
import { Search, Spinner } from '@shared/ui';
import { SegmentedButton } from '@shared/ui/segmentedButton/SegmentedButton';

const Initiatives = () => {
    const { t } = useTranslation(namespaces.features.initiatives);

    const [listParams, setListParams] = useState<GetInitiativesFilterParam>({
        emergingTechnologies: [],
        steps: [],
        statuses: [],
        itStreams: [],
        partners: [],
        contacts: [],
        title: '',
        orderBy: 'LastStepUpdate',
        orderDescendant: false,
    });

    const { data, isFetching } = useGetInitiatives(listParams);
    const reports = useMemo(() => data?.reports as InitiativeReport[], [data?.reports]);
    const filters = useMemo(() => data?.filters as unknown as InitiativesFilterType, [data?.filters]);

    const handleTitleChange = useCallback((title: string) => {
        setListParams(prevParams => ({ ...prevParams, title }));
    }, []);

    return (
        <section className="flex flex-grow flex-row pt-header-height" id="initiatives">
            <article className="w-full ">
                <div className="flex flex-col items-center bg-neutral-oak-5 py-6">
                    <div className="w-full max-w-[750px]">
                        <SegmentedButton
                            options={[
                                { name: 'Discovery', url: 'discovery', selected: false },
                                { name: 'Ideation', url: 'ideation', selected: false },
                                { name: 'Initiatives', url: 'initiatives', selected: true },
                            ]}
                        ></SegmentedButton>
                    </div>
                    <div className="inline-flex">
                        <span className="body-s-book leading-5 text-neutral-oak-100">
                            {t('funnel-text.normal')}&nbsp;
                        </span>
                        <span className="body-s-bold leading-5 text-neutral-oak-100">{t('funnel-text.bold')}</span>
                    </div>
                </div>
                <div className=" m-0 flex px-20 pt-6">
                    <div className="w-[300px] min-w-[300px] overflow-y-auto">
                        <div className="mb-3 flex h-7 items-center py-1">
                            <span className="body-m-book">{t('search-filters-title')}</span>
                        </div>
                        <Search title={listParams.title || ''} onTitleChange={handleTitleChange} />
                        <InitiativesFilterContent
                            filters={filters}
                            listParams={listParams}
                            setListParams={setListParams}
                        />
                    </div>

                    <div className="flex h-[calc(100vh-230px)] w-full flex-col">
                        <InitiativesHeader
                            listParams={listParams}
                            setListParams={setListParams}
                            total={reports?.length}
                        />
                        <div className="w-full flex-grow overflow-y-auto">
                            {isFetching ? <Spinner /> : <InitiativesReports reports={reports} filters={filters} />}
                        </div>
                    </div>
                </div>
            </article>
        </section>
    );
};

export { Initiatives };
