import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ReportFilterType, KeyValuePair, FilterType } from '../domain';
import { bulletColorMapping } from '../list/header/colorMapping';

import { FilterListItem } from './FilterListItem';

import { ReactComponent as ChevronDown } from '@assets/icons/chevronDown.svg';
import { ReactComponent as ChevronRight } from '@assets/icons/chevronRight.svg';
import { namespaces } from '@shared/constants';

export type FilterCategory = {
    type: ReportFilterType;
    options: FilterType[];
    isOpen: boolean;
    bulletColor?: string;
};

type ReportFilterProps = {
    filterCategories: FilterCategory[];
    listParams: Record<ReportFilterType, KeyValuePair[]>;
    toggleCategory: (category: ReportFilterType) => void;
    toggleTagCallback: (listItem: KeyValuePair, tagType: ReportFilterType) => void;
    avatar?: string;
};

const InitiativesFilter = ({ filterCategories, listParams, toggleCategory, toggleTagCallback }: ReportFilterProps) => {
    const { t } = useTranslation(namespaces.features.initiatives);
    const [openCategory, setOpenCategory] = useState<ReportFilterType | null>(filterCategories[0]?.type || null);
    const [openItems, setOpenItems] = useState<Record<string, boolean>>({});

    const isAnyChildOpen = Object.values(openItems).some(isOpen => isOpen);

    const handleToggleCategory = (category: ReportFilterType) => {
        setOpenCategory(prev => (prev === category ? null : category));
        toggleCategory(category);
    };

    const handleToggleItem = (key: string) => {
        setOpenItems(prev => ({ ...prev, [key]: !prev[key] }));
    };

    const renderOptions = (
        options: FilterType[],
        categoryType: ReportFilterType,
        bulletColor?: string,
        indentLevel = 0
    ) => {
        return (
            <ul
                className="ml-6 flex max-h-[calc(100vh-660px)] flex-row flex-wrap gap-3 overflow-y-auto
                pb-4 sm:block sm:pb-0"
            >
                {options.map(filter => {
                    const isActive = !!listParams[categoryType].find(option => option.key === filter.key);
                    const hasChildren = filter.children && filter.children.length > 0;
                    const isParent = filter.children;

                    return (
                        <li
                            key={filter.key}
                            className={`pl-[${indentLevel * 24}px]`}
                            style={{ paddingLeft: `${indentLevel * 16}px` }}
                        >
                            <div className="flex items-center">
                                <div className="p-1">
                                    {hasChildren ? (
                                        <div
                                            onClick={() => handleToggleItem(filter.key)}
                                            className="flex h-4 min-w-[16px] justify-center"
                                        >
                                            {openItems[filter.key] ? <ChevronDown /> : <ChevronRight />}
                                        </div>
                                    ) : (
                                        isParent && <div className="w-4"></div>
                                    )}
                                </div>
                                <FilterListItem
                                    key={filter.key}
                                    listItem={filter}
                                    type={categoryType}
                                    toggleTagCallback={toggleTagCallback}
                                    bulletColor={bulletColorMapping[filter.key] || bulletColor}
                                    isActive={isActive}
                                />
                            </div>
                            {hasChildren &&
                                openItems[filter.key] &&
                                renderOptions(filter.children || [], categoryType, bulletColor, indentLevel + 1)}
                        </li>
                    );
                })}
            </ul>
        );
    };

    return (
        <div className={`w-full sm:grid sm:gap-6 ${isAnyChildOpen ? 'overflow-hidden' : 'overflow-auto'}`}>
            <div className="accordion">
                {filterCategories.map((category, index) => (
                    <div key={category.type} className="accordion-item">
                        <div
                            className="accordion-title flex cursor-pointer items-center py-[18px]"
                            onClick={() => handleToggleCategory(category.type)}
                        >
                            <div className="p-1">
                                <div className="w-4">
                                    {openCategory === category.type ? <ChevronDown /> : <ChevronRight />}
                                </div>
                            </div>
                            <h2 className="flex-1 p-[1px] text-xl">{t(`filters.${category.type.toString()}.title`)}</h2>
                        </div>
                        {openCategory === category.type && (
                            <div className="accordion-content mb-3">
                                {renderOptions(category.options, category.type, category.bulletColor)}
                            </div>
                        )}
                        {index < filterCategories.length - 1 && <hr className="border-neutral border-t" />}
                    </div>
                ))}
            </div>
        </div>
    );
};

export { InitiativesFilter };
