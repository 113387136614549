import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Outlet, useLocation } from 'react-router-dom';

import { getRouteData } from '../getRouteData';
import { routeDataObject } from '../routeDataObject';

import { transitionalAuthStatus } from '@features/authentication';
import { checkPermission, useMeInformationQuery } from '@features/authorization';
import { LazyPublicLandingPage } from '@pages/lazyLoadingPages';
import NoAccessPage from '@pages/NoAccessPage';

const RouteGuard = () => {
    const { inProgress } = useMsal();
    const { pathname } = useLocation();
    const isAuthenticated = useIsAuthenticated();
    const { data: meInformation } = useMeInformationQuery();
    const currentRouteData = getRouteData(pathname, routeDataObject);
    const neededPermission = currentRouteData && currentRouteData.permission;

    if (transitionalAuthStatus.includes(inProgress)) {
        return <></>;
    }

    if (!isAuthenticated) {
        return <LazyPublicLandingPage />;
    }

    // if we have a user and the userPermissions variable is null, it means it is still loading
    if (!meInformation?.permissions) {
        return <></>;
    }

    const hasPermission = checkPermission(meInformation?.permissions, neededPermission ? [neededPermission] : []);

    if (!hasPermission) {
        return <NoAccessPage />;
    }

    return <Outlet />;
};

export { RouteGuard };
