import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { getReportList } from './api';
import { reportsValidator, Reports, GetInitiativesFilterParam } from './domain';

import { retrieveFromCache, saveToCache } from '@shared/caching';

const queryKey = 'reports';

export const useGetInitiatives = (params?: GetInitiativesFilterParam) => {
    const cacheKey = useMemo(() => `${queryKey}-${JSON.stringify(params)}`, [params]);

    return useQuery([queryKey, params], async () => getReportList(params), {
        onSuccess: (data: Reports) => {
            saveToCache(cacheKey, data || {});
        },
        placeholderData: () => {
            const cachedData = retrieveFromCache(cacheKey, reportsValidator.parse);
            return cachedData
                ? { reports: cachedData.reports, filters: cachedData.filters }
                : { reports: [], filters: [] };
        },
    });
};
