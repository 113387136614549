import { Contact } from '../contact/Contact';

import { ReactComponent as MailIcon } from '@assets/icons/mail.svg';

type Props = {
    name: string;
    email: string;
    photo?: string | null;
    showMailIcon: boolean;
};

function ContactBoxWithActions({ email, name, photo }: Omit<Props, 'showMailIcon'>) {
    return (
        <a role="link" className="flex items-center justify-between" href={`mailto:${email}`}>
            <Contact name={name} photo={photo} />
            <div className="pr-11 text-blue-800">
                <MailIcon />
            </div>
        </a>
    );
}

function ContactBox({ email, name, photo, showMailIcon }: Props) {
    return (
        <>
            {showMailIcon ? (
                <ContactBoxWithActions email={email} name={name} photo={photo} />
            ) : (
                <div className="flex items-center justify-between">
                    <Contact name={name} photo={photo} />
                </div>
            )}
        </>
    );
}

export { ContactBox };
