import { TFunction } from 'i18next';

import { getInitiativeStepString } from '@features/initiatives/domain';

type Props = {
    step: number;
    status: string;
    t: TFunction;
};

const STATUS_CLASSES: Record<string, { bg: string; border: string }> = {
    in_progress: { bg: 'bg-semantic-green-60', border: 'border-semantic-green-60' },
    standby: { bg: 'bg-semantic-orange-60', border: 'border-semantic-orange-60' },
    rejected: { bg: 'bg-semantic-red-60', border: 'border-semantic-red-60' },
    completed: { bg: 'bg-neutral-oak-45', border: 'border-neutral-oak-45' },
    planned: { bg: 'bg-primary-blue-dark-60', border: 'border-primary-blue-dark-60' },
};

export const InitiativeCardHeader = ({ step, status, t }: Props) => {
    const { bg, border } = STATUS_CLASSES[status] || { bg: 'bg-semantic-green-60', border: 'border-semantic-green-60' };

    return (
        <div className="grid w-full grid-cols-3 ">
            <div className="body-s-book col-span-2 rounded-l border border-r-0 border-primary-blue-30 py-1 text-center">
                {t(`step.${getInitiativeStepString(step)}`)}
            </div>

            <div className={`${bg} ${border} body-s-bold col-span-1 rounded-r py-1 text-center text-white`}>
                {t(status)}
            </div>
        </div>
    );
};
