import { Suspense } from 'react';

import { Outlet, Route, Routes } from 'react-router-dom';

import { routeDataObject } from './routeDataObject';
import { RouteGuard } from './RouteGuard';

import { Detail, Summary } from '@features/coInnovationFunds';
import { Funds, FundDetail } from '@features/coInnovationFunds/funds';
import { Guidelines } from '@features/coInnovationFunds/guidelines/Guidelines';
import { Discovery } from '@features/discovery/Discovery';
import { SingleEvent } from '@features/events/SingleEvent';
import { InitiativeDetailPage } from '@features/initiatives/detail/InitiativeDetailPage';
import { LoadingBar } from '@features/layout';
import { ReportDetailPage } from '@features/reportGenaiUseCases/ReportDetailPage';
import {
    LazyBlogPage,
    LazyErrorPage,
    LazyDashboardPage,
    LazySingleBlogPage,
    LazyGenAiPage,
    LazyPrivateLandingPage,
    LazyEventsPage,
    LazyInitiativesPage,
    LazyTechPositionStatementsPage,
} from '@pages/lazyLoadingPages';
import PreLandingPage from '@pages/PreLandingPage';
import { ErrorManagement } from '@shared/errorManagement';
import { usePageViewTracker } from '@shared/telemetry';

const coInnovationRoutes = routeDataObject.coInnovationFunds.routes;

const ApplicationRoutes = () => {
    usePageViewTracker();

    return (
        <Suspense fallback={<LoadingBar />}>
            <Routes>
                <Route
                    element={
                        <Suspense fallback={<></>}>
                            <ErrorManagement>
                                <RouteGuard />
                            </ErrorManagement>
                        </Suspense>
                    }
                >
                    {/* Routes protected by the RouteGuard */}
                    <Route path={routeDataObject.landing.path} element={<LazyPrivateLandingPage />} />
                    <Route path={routeDataObject.preHome.path} element={<PreLandingPage />} />

                    <Route
                        path={routeDataObject.coInnovationFunds.path}
                        element={
                            <LazyDashboardPage>
                                <Outlet />
                            </LazyDashboardPage>
                        }
                    >
                        <Route index element={<Summary />} />
                        <Route path={coInnovationRoutes.details.path} element={<Detail />} />
                        <Route path={coInnovationRoutes.fundsManagement.path} element={<Funds />} />
                        <Route path={coInnovationRoutes.fundsManagementDetail.path} element={<FundDetail />} />
                        <Route path={coInnovationRoutes.guidelines.path} element={<Guidelines />} />
                        <Route path={coInnovationRoutes.requests.path} element={<div>Requests</div>} />
                    </Route>

                    <Route path={routeDataObject.initiatives.path} element={<LazyInitiativesPage />} />
                    <Route path={routeDataObject.initiativesDetail.path} element={<InitiativeDetailPage />} />
                    <Route path={routeDataObject.newInitiativesDetail.path} element={<InitiativeDetailPage />} />
                    <Route path={routeDataObject.report.path} element={<LazyGenAiPage />} />
                    <Route path={routeDataObject.reportDetail.path} element={<ReportDetailPage />} />

                    <Route path={routeDataObject.blog.path} element={<Outlet />}>
                        <Route index element={<LazyBlogPage />} />
                        <Route path={routeDataObject.blog.routes.single.path} element={<LazySingleBlogPage />} />
                    </Route>

                    <Route path={routeDataObject.techPositions.path} element={<Outlet />}>
                        <Route index element={<LazyTechPositionStatementsPage />} />
                    </Route>

                    <Route path={routeDataObject.events.path} element={<LazyEventsPage />} />
                    <Route path={routeDataObject.eventDetail.path} element={<SingleEvent />} />

                    <Route path={routeDataObject.discovery.path} element={<Discovery />} />
                </Route>
                {/* Routes that are available for anyone */}
                <Route path="*" element={<LazyErrorPage errorCode={404} />} />
            </Routes>
        </Suspense>
    );
};

export { ApplicationRoutes };
