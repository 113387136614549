import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { InitiativeReport, InitiativesFilterType } from '../types';

import { InitiativeCard } from './card/InitiativeCard';

import { ReactComponent as NoData } from '@assets/images/noData.svg';

type InitiativesReportsProps = {
    reports?: InitiativeReport[];
    filters: InitiativesFilterType;
};

export const InitiativesReports = ({ reports, filters }: InitiativesReportsProps) => {
    const { t } = useTranslation();

    const emergingTechObj = useMemo(() => {
        return filters?.emergingTechnologies?.reduce((acc: { [key: string]: string[] }, tech) => {
            const parentKey = tech.key;
            const childrenKeys = tech?.children?.map(child => child.key) ?? [];
            acc[parentKey] = childrenKeys;
            return acc;
        }, {});
    }, [filters.emergingTechnologies]);

    const gridClass =
        reports && reports.length < 4
            ? 'md:grid-cols-[repeat(auto-fit,_minmax(314px,_400px))]'
            : 'md:grid-cols-[repeat(auto-fit,_minmax(314px,_1fr))]';

    return (
        <div>
            {reports && reports.length > 0 ? (
                <div className={`grid gap-6 px-6 pb-6 pt-1 ${gridClass}`}>
                    {reports.map(initiative => (
                        <InitiativeCard
                            t={t}
                            emergingTechObj={emergingTechObj}
                            key={initiative.id}
                            initiative={initiative}
                        />
                    ))}
                </div>
            ) : (
                <div className="flex flex-col items-center justify-center">
                    <NoData />
                </div>
            )}
        </div>
    );
};
