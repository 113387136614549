import { PropsWithChildren, ReactNode, useEffect, useRef, useState } from 'react';

import { CSSTransition } from 'react-transition-group';

import classes from './dropdown.module.css';

import { useResizeMediaQuery } from '@features/layout/useResizeMediaQuery';

type Props = {
    title?: ReactNode | undefined;
    dropdownClassNames?: string;
    isClicked?: boolean;
};

const Dropdown = ({ children, title, dropdownClassNames, isClicked = false }: PropsWithChildren<Props>) => {
    const ref = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { isMobile } = useResizeMediaQuery();

    useEffect(() => {
        setIsOpen(isClicked);
    }, [isClicked]);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleIsOpen = () => {
        if (!isMobile) {
            setIsOpen(true);
        }
    };

    const handleIsClose = () => {
        if (!isMobile) {
            setIsOpen(false);
        }
    };

    return (
        <span
            className="group relative inline-block"
            onClick={handleToggle}
            onMouseEnter={handleIsOpen}
            onMouseLeave={handleIsClose}
            onFocus={handleIsOpen}
            onBlur={handleIsClose}
            ref={ref}
        >
            <span className="block">{title}</span>

            <CSSTransition
                in={isOpen}
                nodeRef={ref}
                timeout={150}
                unmountOnExit
                classNames={{
                    enter: classes.enter,
                    enterActive: classes.enterActive,
                    exit: classes.exit,
                    exitActive: classes.exitActive,
                }}
            >
                <div ref={ref} className={dropdownClassNames}>
                    {children}
                </div>
            </CSSTransition>
        </span>
    );
};

export { Dropdown };
