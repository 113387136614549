import { formatDateWithoutSpecialCharacters } from './formatDate';

export const truncateString = (str: string, maxLength: number) => {
    if (str.length <= maxLength) {
        return str;
    }

    return str.substring(0, maxLength) + '...';
};

export const determineTruncatedString = (str: string, isParent: boolean, assignedToInitiative: number): string => {
    const initiativeLength = assignedToInitiative.toString().length;
    const baseLength = isParent ? 20 : 17;
    const maxLength = baseLength - initiativeLength;

    if (str.length <= maxLength) {
        return str;
    }

    return truncateString(str, maxLength);
};

export const formatFundNameSuggestion = (
    partnerAbbreviation: string | null | undefined,
    nullAbbreviationReplacement: string,
    date: Date | null | undefined
): string => {
    return `${partnerAbbreviation ?? nullAbbreviationReplacement}-${formatDateWithoutSpecialCharacters(
        date ?? new Date()
    )}`;
};
