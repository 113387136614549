import { FunctionComponent, SVGProps } from 'react';

import classNames from 'classnames';

type ButtonProps = {
    variant?: 'link' | 'primary' | 'secondary';
    icon?: FunctionComponent<SVGProps<SVGSVGElement>> | undefined;
    iconPosition?: 'left' | 'right' | undefined;
};

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps;

export const Button = ({ variant, children, icon: Icon, iconPosition: IconPosition, ...rest }: Props) => {
    return (
        <button
            {...rest}
            className={classNames(rest.className, {
                'btn-style': variant === 'primary',
                'btn-outline-style': variant === 'secondary',
                btn: variant === 'link',
            })}
            style={{
                display: Icon ? 'flex' : undefined,
                alignItems: Icon ? 'center' : undefined,
            }}
        >
            {(IconPosition !== 'right' || IconPosition === undefined) && Icon && <Icon className="ml-2 text-white" />}
            {children}
            {IconPosition === 'right' && Icon && <Icon className="ml-2 text-white" />}
        </button>
    );
};
