import * as React from 'react';
import { ReactNode } from 'react';

type Props = {
    values: string[];
    children: ReactNode;
};

export const InitiativeCardDetailRow = ({ children, values }: Props) => {
    const hasValue = values.length > 0;
    const displayValue = hasValue ? values[0] : '-';
    const textColor = hasValue ? 'text-primary-blue-100' : 'text-neutral-oak-45';

    return (
        <div className="flex w-full flex-row align-middle text-xs">
            <div className={`mx-2 flex items-center ${textColor}`}>
                {React.cloneElement(children as React.ReactElement, {})}
            </div>
            <div className={`mx-2 flex items-center ${textColor}`}>{displayValue}</div>
            {hasValue && values.length > 1 && <div className="flex items-center font-bold">+{values.length - 1}</div>}
        </div>
    );
};
