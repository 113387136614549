import { useMemo } from 'react';

import { bulletColorMapping } from '../header/colorMapping';

export const useTagMapping = (emergingTechObj: { [key: string]: string[] } | null | undefined) => {
    const parentKeyMap = useMemo(() => {
        if (!emergingTechObj) return new Map();

        const map = new Map<string, string | null>();
        for (const [parentKey, childrenKeys] of Object.entries(emergingTechObj)) {
            childrenKeys.forEach(tagId => {
                map.set(tagId, parentKey);
            });
        }
        return map;
    }, [emergingTechObj]);

    const getBulletColor = (tagId: string): string | null => {
        const parentKey = parentKeyMap.get(tagId);
        return parentKey ? bulletColorMapping[parentKey] || null : null;
    };

    return { getBulletColor };
};
