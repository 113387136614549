import { Dispatch, SetStateAction } from 'react';

import { useTranslation } from 'react-i18next';

import { KeyValuePair, GetInitiativesFilterParam, InitiativeSortingOption } from '../../domain';

import { bulletColorMapping } from './colorMapping';
import { InititativesHeaderTag } from './InititativesHeaderTag';

import { hasActiveFilters } from '@features/initiatives/utils';
import { namespaces } from '@shared/constants';
import { DropdownFilter } from '@shared/ui/dropdownFilter/DropdownFilter';

type Props = {
    listParams: GetInitiativesFilterParam;
    setListParams: Dispatch<SetStateAction<GetInitiativesFilterParam>>;
    total: number;
};

export const InitiativesHeader = ({ listParams, setListParams, total }: Props) => {
    const { t } = useTranslation(namespaces.features.initiatives);
    const { emergingTechnologies, steps, statuses, itStreams, partners, contacts } = listParams;

    const InitiativesSortingOptions: { label: string; value: InitiativeSortingOption; desc: boolean }[] = [
        { label: t('sort.alphabetically'), value: 'Alphabetically', desc: false },
        { label: t('sort.lastStepUpdate'), value: 'LastStepUpdate', desc: true },
        { label: t('sort.recent'), value: 'Recent', desc: true },
        { label: t('sort.oldest'), value: 'Oldest', desc: false },
    ];

    const selectedOption = InitiativesSortingOptions.find(option => option.value === listParams.orderBy);

    const handleSortingChange = (orderBy: InitiativeSortingOption, orderDescendant: boolean) => {
        setListParams(prevState => ({
            ...prevState,
            orderBy,
            orderDescendant,
        }));
    };

    const renderFilterTags = (filterArray: KeyValuePair[], filterName: keyof typeof listParams) => {
        return filterArray.map(filter => (
            <InititativesHeaderTag
                key={filter.key}
                filterType={filterName}
                translationKey={filterName}
                value={filter.value}
                text={t(filter.value)}
                bulletColor={bulletColorMapping[filter.key]}
                onClickCallback={() => {
                    setListParams(previousState => ({
                        ...previousState,
                        [filterName]: Array.isArray(previousState[filterName])
                            ? (previousState[filterName] as KeyValuePair[]).filter(value => value.key !== filter.key)
                            : previousState[filterName],
                    }));
                }}
            />
        ));
    };

    return (
        <header>
            <div className="mb-6 flex flex-nowrap justify-between pl-6">
                {hasActiveFilters(listParams) ? (
                    <div className="flex flex-wrap gap-3">
                        {emergingTechnologies && renderFilterTags(emergingTechnologies, 'emergingTechnologies')}
                        {steps && renderFilterTags(steps, 'steps')}
                        {statuses && renderFilterTags(statuses, 'statuses')}
                        {itStreams && renderFilterTags(itStreams, 'itStreams')}
                        {partners && renderFilterTags(partners, 'partners')}
                        {contacts && renderFilterTags(contacts, 'contacts')}
                    </div>
                ) : null}
                <div
                    className={`flex h-auto items-center gap-3 self-start whitespace-nowrap ${
                        !hasActiveFilters(listParams) ? 'w-full justify-end' : ''
                    }`}
                >
                    {hasActiveFilters(listParams) ? (
                        <div className="flex h-auto items-center gap-3">
                            <span
                                className="cursor-pointer whitespace-nowrap rounded-[4px] border border-blue-900 px-[6px] py-[4px] text-[12px] text-blue-900"
                                id="resetAll"
                                onClick={() =>
                                    setListParams(previousState => ({
                                        ...previousState,
                                        emergingTechnologies: [],
                                        steps: [],
                                        statuses: [],
                                        itStreams: [],
                                        partners: [],
                                        contacts: [],
                                        title: '',
                                        orderBy: previousState.orderBy,
                                        orderDescendant: previousState.orderDescendant,
                                    }))
                                }
                            >
                                {t('resetAll')}
                            </span>
                            <span className="body-m-book whitespace-nowrap text-neutral-oak-dark">
                                {total || 0} {t('results')}
                            </span>
                            <div className="h-[1.2rem] w-[1px] bg-neutral-oak-30"></div>
                        </div>
                    ) : null}
                    <DropdownFilter<InitiativeSortingOption>
                        options={InitiativesSortingOptions}
                        selectedOption={selectedOption || null}
                        onSelect={handleSortingChange}
                        placeholder={t('sort.placeholder')}
                    />
                </div>
            </div>
        </header>
    );
};
