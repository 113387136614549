type FundDetailSectionProps = {
    title: string;
    children: React.ReactNode;
};

export const FundDetailSection = ({ title, children }: FundDetailSectionProps) => {
    return (
        <div className=" flex-1 rounded-lg border border-blue-100 text-center md:border-l">
            <div className="flex max-h-[60vh] min-h-[400px] flex-col gap-5 pb-5">
                <h3 className="book-m-book border-b border-blue-100 p-5 text-left">{title}</h3>
                {children}
            </div>
        </div>
    );
};
