import { ReportDetail, reportDetailValidator } from '../reportGenaiUseCases/domain';

import { GetInitiativesFilterParam, Reports } from './domain';

import { agent, queryStringComposer } from '@shared/api';

const apiRoutes = {
    getReportList: '/api/reportlist',
    getInitiativesFilters: '/api/reportfilterlist',
};

export const getReportList = async (params?: GetInitiativesFilterParam): Promise<Reports | null> => {
    const queryParams = queryStringComposer()
        .addQueryStringValueFor(
            'EmergingTechnology',
            params?.emergingTechnologies?.map(x => {
                if (!/\d/.test(x.key)) {
                    return x.key;
                } else {
                    return '_' + x.key;
                }
            })
        )
        .addQueryStringValueFor(
            'Step',
            params?.steps?.map(x => x.key)
        )
        .addQueryStringValueFor(
            'Status',
            params?.statuses?.map(x => x.key)
        )
        .addQueryStringValueFor(
            'Partners',
            params?.partners?.map(x => x.key)
        )
        .addQueryStringValueFor(
            'ItTeam',
            params?.itStreams?.map(x => x.key)
        )
        .addQueryStringValueFor(
            'Contacts',
            params?.contacts?.map(x => x.key)
        )
        .addQueryStringValueFor('Title', params?.title ? [params.title] : undefined)
        .addQueryStringValueFor('OrderBy', [params?.orderBy])
        .addQueryStringValueFor(
            'OrderDescendant',
            params?.orderDescendant !== undefined ? [params.orderDescendant] : undefined
        )
        .compose();
    const response = await agent.get<Reports>(`${apiRoutes.getReportList}${queryParams}`);
    return response ? response : null;
};

export const getReportDetail = async (id: string) => {
    const response = await agent.get<ReportDetail>(`/api/initiativesDetail?Id=${id}`);

    return reportDetailValidator.parseAsync(response);
};
