import { Suspense, FC } from 'react';

import { useTranslation } from 'react-i18next';

import DiscoveryImage from '../assets/images/DiscoveryLanding.png';
import IdeationImage from '../assets/images/IdeationLanding.png';
import InitiativesImage from '../assets/images/InitiativesLanding.png';
import HomeImage from '../assets/images/preLanding.png';
import VectorImage from '../assets/images/VectorLanding.png';

import { ReactComponent as ChevronDown } from '@assets/icons/chevronDown.svg';
import { namespaces } from '@shared/constants';

import './landingPage.scss';

interface LandingPageProps {
    section: 'discovery' | 'ideation' | 'initiatives';
}

const LandingPage: FC<LandingPageProps> = ({ section }) => {
    const { t } = useTranslation(namespaces.features.landing);

    return (
        <div className="min-h-screen pt-header-height">
            <main className="mx-auto flex max-w-[1512px] flex-col">
                <Suspense>
                    <div className="items-center justify-center bg-secondary-white">
                        <section className="flex items-center space-x-8 pt-6">
                            <div className="relative -mr-24 flex-1">
                                <h1 className="max-w-full text-start font-nestle text-[64px] font-bold leading-[72px]">
                                    {t('preLanding.Title.main')}{' '}
                                    <span className="text-primary-blue-100">{t('preLanding.Title.highlight')}</span>
                                </h1>
                                <p className="mt-6 text-start text-xl ">
                                    {t('preLanding.Subtitle.start')} <strong>{t('preLanding.Subtitle.bold')}</strong>
                                </p>
                            </div>
                            <div className="flex-1">
                                <img className="h-[410px]" src={HomeImage} alt="Discovery" />
                            </div>
                        </section>

                        <section className="mt-responsive relative items-center justify-center">
                            <div className="relative mx-auto  h-[215px] w-[788px]">
                                <div className="flex flex-col items-center">
                                    <h2 className="text-center font-nestle text-base md:mb-6">
                                        {t('preLanding.AccelerateTitle.start')}{' '}
                                        <strong>{t('preLanding.AccelerateTitle.bold')}</strong>
                                    </h2>
                                </div>
                                <img
                                    src={VectorImage}
                                    alt="Vector"
                                    className="absolute inset-x-2 bottom-12 z-20 w-9/12"
                                />
                                <div className="relative grid grid-cols-1 items-center gap-6 md:grid-cols-3">
                                    <div className="flex flex-col items-center border-b border-blue-400 bg-secondary-white">
                                        <img src={DiscoveryImage} alt="Discovery" className="mx-auto" />
                                        <h3 className="my-2 font-bold">{t('preLanding.Cards.titles.discovery')}</h3>
                                    </div>
                                    <div className="flex flex-col items-center border-b border-blue-400 bg-secondary-white">
                                        <img src={IdeationImage} alt="Ideation" className="mx-auto " />
                                        <h3 className="my-2 font-bold">{t('preLanding.Cards.titles.ideation')}</h3>
                                    </div>

                                    <div className="flex flex-col items-center border-b border-blue-400 bg-secondary-white">
                                        <img src={InitiativesImage} alt="Initiatives" className="mx-auto" />
                                        <h3 className="my-2 font-bold">{t('preLanding.Cards.titles.initiatives')}</h3>
                                    </div>
                                </div>
                                <div className="relative mt-2 mb-[64px] grid grid-cols-1 items-center gap-6 text-neutral-oak-100 md:grid-cols-3">
                                    <div className="flex flex-col items-center bg-secondary-white text-center text-sm">
                                        <p>{t('preLanding.Cards.descriptions.discovery')}</p>
                                    </div>
                                    <div className="flex flex-col items-center bg-secondary-white text-center font-nestle text-sm">
                                        <p>{t('preLanding.Cards.descriptions.ideation')}</p>
                                    </div>
                                    <div className="flex flex-col items-center bg-secondary-white text-center text-sm">
                                        <p>{t('preLanding.Cards.descriptions.initiatives')}</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Suspense>
            </main>
            <footer className="absolute bottom-0 flex w-full flex-col items-center border-b-2 border-gray-100 bg-gray-50 p-5">
                <button
                    onClick={() => {
                        const newUrl = `?page=${section ? section : 'discovery'}#section`;
                        window.history.pushState(null, '', newUrl);
                        const sectionElement = document.getElementById('section');
                        if (sectionElement) {
                            sectionElement.scrollIntoView({ behavior: 'smooth' });
                        }
                    }}
                    className="absolute bottom-11 z-20 animate-pulse-btn cursor-pointer rounded-3xl bg-primary-blue-100 px-6 py-2 text-secondary-white"
                >
                    <ChevronDown className="stroke-secondary-white" />
                </button>
                <p className="font-nestle text-xs">
                    {t('preLanding.FooterText.start')} <strong>{t('preLanding.FooterText.bold')}</strong>
                </p>
            </footer>
        </div>
    );
};

export default LandingPage;
