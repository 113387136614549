import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { CoInnovationFund } from '../domain';

import { namespaces } from '@shared/constants';
import { formatDate } from '@shared/formatting/formatDate';

type PanelStatusDetailInitProps = {
    data?: CoInnovationFund | undefined;
};

export const TopDetail: FC<PanelStatusDetailInitProps> = ({ data }) => {
    const { t } = useTranslation(namespaces.features.coInnovationFunds);

    return (
        <div className="flex gap-4 px-8 pb-6 pt-9 md:px-8" aria-label="Fund Key Performance Indicators">
            <div className="flex-auto flex-auto rounded-lg border-2 border-blue-100 bg-blue-50 py-4 px-5 text-left">
                <p className="body-s-book leading-5 text-basic-blue">
                    {t('coInnovationFunds.fundsManagement.submitDate')}
                </p>
                <p className="body-l-bold py-2">{formatDate(data?.submissionDate ?? '')}</p>
            </div>
            <div className="flex-auto rounded-lg border-2 border-blue-100 bg-blue-50 py-4 px-5 text-left md:flex-auto ">
                <p className="body-s-book leading-5 text-basic-blue">
                    {t('coInnovationFunds.fundsManagement.partner')}
                </p>
                <p className="row-gap body-l-bold py-2">{data?.partner.name}</p>
            </div>
            <div className="flex-auto rounded-lg border-2 border-blue-100 py-4 px-5 text-left md:flex-auto">
                <p className="body-s-book leading-5 text-basic-blue">
                    {t('coInnovationFunds.fundsManagement.beginDate')}
                </p>
                <p className="body-l-book py-2 text-neutral-oak-dark">{formatDate(data?.beginDate ?? '')}</p>
            </div>
            <div className="flex-auto rounded-lg border-2 border-blue-100 py-4 px-5 text-left md:flex-auto ">
                <p className="body-s-book leading-5 text-basic-blue">
                    {t('coInnovationFunds.fundsManagement.consumptionExpirationDate')}
                </p>
                <p className="body-l-book py-2 text-neutral-oak-dark">
                    {formatDate(data?.consumptionExpirationDate ?? '')}
                </p>
            </div>
        </div>
    );
};
