import { Tag } from '@features/initiatives/domain';
import { determineTruncatedString } from '@shared/formatting/formatString';

type TagItemProps = {
    tag: Tag;
    bulletColor: string | null;
    showBullet: boolean;
};

export const TagItem = ({ tag, bulletColor, showBullet }: TagItemProps) => (
    <div className="flex items-center rounded border border-primary-blue-30 px-2 py-1">
        {showBullet && bulletColor && (
            <div className="flex items-center justify-center px-[7px] py-[1px]">
                <span
                    className={`inline-block h-3 w-[10px] ${bulletColor}`}
                    style={{
                        clipPath: 'polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)',
                    }}
                ></span>
            </div>
        )}
        <span className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-[12px]">
            {determineTruncatedString(tag.value || '', false, 10)}
        </span>
    </div>
);
