import { lazy } from 'react';

const LazyBlogPage = lazy(() => import('@pages/BlogHomePage'));
const LazyErrorPage = lazy(() => import('@pages/ErrorPage'));
const LazyEventsPage = lazy(() => import('@pages/EventsPage'));
const LazyDashboardPage = lazy(() => import('@pages/DashboardPage'));
const LazyGenAiPage = lazy(() => import('@pages/GenAiPage'));
const LazyInitiativesPage = lazy(() => import('@pages/InitiativesPage'));
const LazySingleBlogPage = lazy(() => import('@pages/SingleBlogPage'));
const LazyPublicLandingPage = lazy(() => import('@pages/PublicLandingPage'));
const LazyPrivateLandingPage = lazy(() => import('@pages/PrivateLandingPage'));
const LazyHomePage = lazy(() => import('@pages/LandingPage'));

const LazyTechPositionStatementsPage = lazy(() => import('@pages/TechPositionStatementsPage'));

export {
    LazyBlogPage,
    LazyErrorPage,
    LazyEventsPage,
    LazyDashboardPage,
    LazyGenAiPage,
    LazySingleBlogPage,
    LazyPublicLandingPage,
    LazyPrivateLandingPage,
    LazyHomePage,
    LazyInitiativesPage,
    LazyTechPositionStatementsPage,
};
