import { useState } from 'react';

import { TagItem } from './TagItem';
import { useTagMapping } from './useTagMapping';

import { Tag } from '@features/initiatives/domain';

type Props = {
    tags: Tag[];
    emergingTechObj: { [key: string]: string[] } | null | undefined;
};

export const InitiativeCardTags = ({ tags, emergingTechObj }: Props) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { getBulletColor } = useTagMapping(emergingTechObj);

    const shownTagCount = tags.length > 1 && (tags[0]?.value?.length ?? 0) + (tags[1]?.value?.length ?? 0) < 25 ? 2 : 1;
    const hiddenTags = tags.slice(shownTagCount);

    if (tags.length === 0) {
        return (
            <div className="flex items-center">
                <button className="inline-flex h-[23px] items-center justify-center rounded bg-neutral-oak-5 px-2 py-1 text-[12px] font-book leading-[20px] text-neutral-oak-45">
                    No tags
                </button>
            </div>
        );
    }

    return (
        <div className="relative flex h-6 w-full gap-2">
            {tags.slice(0, shownTagCount).map(tag => (
                <TagItem key={tag.key} tag={tag} bulletColor={getBulletColor(tag.key)} showBullet={!!emergingTechObj} />
            ))}
            {hiddenTags.length > 0 && (
                <div
                    onMouseEnter={() => setIsExpanded(true)}
                    onMouseLeave={() => setIsExpanded(false)}
                    className="flex items-center rounded border border-primary-blue-100 p-2 text-primary-blue-100"
                >
                    {`+${hiddenTags.length}`}
                    {isExpanded && (
                        <div
                            className="absolute z-10 ml-2 mt-11 flex min-w-[10rem] max-w-[24rem] translate-y-1/2
                            -translate-x-1/2 flex-wrap gap-2 rounded-md border border-primary-blue-30 bg-white p-2 text-black shadow-lg"
                        >
                            <div
                                className="absolute top-[-6px] left-1/2 h-3 w-3 -translate-x-1/2 rotate-45 border
                                border-primary-blue-30 border-b-white border-r-white bg-white"
                            ></div>
                            <div className="flex flex-col gap-2">
                                {hiddenTags.map(tag => (
                                    <TagItem
                                        key={tag.key}
                                        tag={tag}
                                        bulletColor={getBulletColor(tag.key)}
                                        showBullet={!!emergingTechObj}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
