import { Dispatch, SetStateAction } from 'react';

import { CellContext, PaginationState } from '@tanstack/react-table';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { checkIfFundFiltersAreActive } from './checkIfFundFiltersAreActive';
import { fundStatuses } from './constants';
import { CoInnovationFundListResponse, SortParams } from './domain';
import { FundMobileTable } from './FundMobileTable';
import { DialogTypes, SelectedFundState } from './Funds';
import { getFundStatusTranslationKey } from './getFundStatusTranslationKey';

import { ReactComponent as ApproveIcon } from '@assets/icons/approveAction.svg';
import { ReactComponent as EditIcon } from '@assets/icons/editAction.svg';
import { ReactComponent as RejectIcon } from '@assets/icons/rejectAction.svg';
import { HasPermission } from '@features/authorization';
import { checkPermission } from '@features/authorization/checkPermission';
import { permissions } from '@features/authorization/permissions';
import { useMeInformationQuery } from '@features/authorization/useMeInformationQuery';
import { useResizeMediaQuery } from '@features/layout';
import { namespaces } from '@shared/constants';
import { formatDate } from '@shared/formatting/formatDate';
import { CustomColumn, MetaCellProps, Table } from '@shared/ui';
import { useStore } from '@store';

//ToDo Single Page Detail - change to: import { formatDate } from '@shared/formatting/formatDate';

type Props = {
    sort: SortParams;
    setSort: Dispatch<SetStateAction<SortParams>>;
    setSelectedFundState: Dispatch<SetStateAction<SelectedFundState | undefined>>;
    isFetching: boolean;
    funds?: CoInnovationFundListResponse | null;
    setPagination: Dispatch<SetStateAction<PaginationState>>;
    pageIndex: number;
    pageSize: number;
    firstItemIndex: number;
    lastItemIndex: number;
    pageCount: number;
};

export type CoInnovationFundTableData = {
    id: string;
    fundName: string;
    submissionDate: string;
    submittedByContact: string;
    partner: string;
    provided: number;
    providedPlusCarryForward: number;
    available?: number;
    expired: number;
    beginDate: string;
    consumptionExpirationDate: string;
    statusId: string;
};

const convertResponseToTableData = (
    param: CoInnovationFundListResponse | undefined | null,
    t: TFunction
): CoInnovationFundTableData[] | undefined =>
    param?.items.map(
        ({
            id,
            fundName,
            submissionDate,
            submittedByContact,
            partner,
            provided,
            carryForward,
            available,
            expired,
            beginDate,
            consumptionExpirationDate,
            statusId,
        }) => ({
            id,
            fundName,
            submissionDate: formatDate(submissionDate),
            submittedByContact: submittedByContact.name,
            partner: partner.name,
            provided,
            providedPlusCarryForward: provided + carryForward,
            available,
            expired,
            beginDate,
            consumptionExpirationDate,
            statusId: t(getFundStatusTranslationKey(statusId)),
        })
    );

const getItemStatusId = (funds: CoInnovationFundListResponse | undefined | null, id: string) => {
    const item = funds?.items.find(item => item.id === id);
    return item?.statusId;
};

const defaultCellProps: MetaCellProps = { className: '', hasWarningIcon: false };

export const FundTable = ({
    funds,
    isFetching,
    sort,
    setSort,
    setSelectedFundState,
    setPagination,
    firstItemIndex,
    lastItemIndex,
    pageCount,
    pageIndex,
    pageSize,
}: Props) => {
    const navigate = useNavigate();

    const { t } = useTranslation(namespaces.features.coInnovationFunds);

    const { isMobile } = useResizeMediaQuery();
    const { data: meInformation } = useMeInformationQuery();

    //Temporarely disabled until https://nestle.atlassian.net/browse/ITINPT-1466 is going to be implemented
    /*const availableMeta = { 
        getCellProps: (context: CellContext<CoInnovationFundTableData, unknown>) => {
            const availableExpiredWarning = t('coInnovationFunds.fundsManagement.availableExpiredWarning');
            return getCellPropsWithWarning(context, availableExpiredWarning, Date.now(), false, 'left');
        },
    };*/

    const fundNameTooltip = t('coInnovationFunds.fundsManagement.tooltips.fundName');
    const submitterTooltip = t('coInnovationFunds.fundsManagement.tooltips.submitter');
    const partnerTooltip = t('coInnovationFunds.fundsManagement.tooltips.partner');
    const proviedTooltip = t('coInnovationFunds.fundsManagement.tooltips.provied');
    const providedPlusCarryForwardTooltip = t('coInnovationFunds.fundsManagement.tooltips.providedPlusCarryForward');
    const availablTooltip = t('coInnovationFunds.fundsManagement.tooltips.available');
    const expiredTooltip = t('coInnovationFunds.fundsManagement.tooltips.expired');
    const beginDateTooltip = t('coInnovationFunds.fundsManagement.tooltips.beginDate');
    const consumptionDeadlineTooltip = t('coInnovationFunds.fundsManagement.tooltips.consumptionDeadline');
    const statusTooltip = t('coInnovationFunds.fundsManagement.tooltips.status');
    const actionsTooltip = t('coInnovationFunds.fundsManagement.tooltips.actions');

    const consumptionExpirationDateMeta = {
        getCellProps: (context: CellContext<CoInnovationFundTableData, unknown>) => {
            const consumptionExpirationWarning = t('coInnovationFunds.fundsManagement.consumptionExpirationWarning');
            const oneMonthFromNow = new Date();
            oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);
            return getCellPropsWithWarning(context, consumptionExpirationWarning, oneMonthFromNow.getTime(), true);
        },
    };

    const getCellPropsWithWarning = (
        context: CellContext<CoInnovationFundTableData, unknown>,
        warningMessage: string,
        comparisonDate: number,
        warningColor = false,
        warningAlignment = 'right'
    ) => {
        const available = context.row.original.available ?? 0;
        const expirationDate = Date.parse(context.row.original.consumptionExpirationDate);

        if (available > 0 && expirationDate < comparisonDate) {
            return {
                ...defaultCellProps,
                warningColor,
                hasWarningIcon: true,
                warningMessage,
                warningAlignment,
            };
        }

        return defaultCellProps;
    };

    type ActionParams = {
        permission: string[];
        iconColor: string;
        iconType: React.ElementType;
        tooltipText: string;
        actionType: DialogTypes;
        statusCheck: number;
    };

    const tableData = convertResponseToTableData(funds, t) || [];

    const canValidateFundsPermissions = [permissions.coInnovationFunds.fundsManagement.validation];
    const canChangeRequestPermissions = [
        permissions.coInnovationFunds.allRequests.create,
        permissions.coInnovationFunds.partnerRequests.create,
    ];

    const validateFundPermission = checkPermission(
        meInformation?.permissions,
        canValidateFundsPermissions,
        'Disjunction'
    );

    const canValidateChangeRequestPermissions = [permissions.coInnovationFunds.fundsManagement.validation];

    const columns: CustomColumn<CoInnovationFundTableData>[] = [
        {
            accessorKey: 'id',
            cellType: 'id',
            visible: false,
        },
        {
            accessorKey: 'fundName',
            header: t('coInnovationFunds.fundsManagement.fundName'),
            cellType: 'link',
            size: 10,
            sortable: true,
            selectedColumnCallback: fundId => navigate(`/co-innovation-funds/funds-management/${fundId}`),
            tooltip: fundNameTooltip,
        },
        {
            accessorKey: 'submittedByContact',
            header: t('coInnovationFunds.fundsManagement.submitter'),
            cellType: 'text',
            size: 10,
            sortable: true,
            tooltip: submitterTooltip,
        },
        {
            accessorKey: 'partner',
            header: t('coInnovationFunds.fundsManagement.partner'),
            cellType: 'text',
            size: 10,
            sortable: true,
            tooltip: partnerTooltip,
        },
        {
            accessorKey: 'provided',
            header: t('coInnovationFunds.fundsManagement.provided'),
            cellType: 'currency',
            size: 8,
            sortable: true,
            tooltip: proviedTooltip,
        },
        {
            accessorKey: 'providedPlusCarryForward',
            header: t('coInnovationFunds.fundsManagement.providedPlusCarryForward'),
            cellType: 'currency',
            size: 10,
            sortable: true,
            tooltip: providedPlusCarryForwardTooltip,
        },
        {
            accessorKey: 'available',
            header: t('coInnovationFunds.fundsManagement.available'),
            cellType: 'currency',
            size: 8,
            sortable: true,
            tooltip: availablTooltip,
            //meta: availableMeta, //Temporarely disabled until https://nestle.atlassian.net/browse/ITINPT-1466 is going to be implemented
        },
        {
            accessorKey: 'expired',
            header: t('coInnovationFunds.fundsManagement.expired'),
            cellType: 'currency',
            size: 8,
            sortable: true,
            tooltip: expiredTooltip,
        },
        {
            accessorKey: 'beginDate',
            header: t('coInnovationFunds.fundsManagement.beginDate'),
            cellType: 'date',
            size: 8,
            alignContent: 'right',
            sortable: true,
            tooltip: beginDateTooltip,
        },
        {
            accessorKey: 'consumptionExpirationDate',
            header: t('coInnovationFunds.fundsManagement.consumptionExpirationDate'),
            cellType: 'date',
            size: 8,
            sortable: true,
            alignContent: 'right',
            meta: consumptionExpirationDateMeta,
            tooltip: consumptionDeadlineTooltip,
        },
        {
            accessorKey: 'statusId',
            header: t('coInnovationFunds.fundsManagement.status'),
            cellType: 'text',
            size: 6,
            sortable: true,
            alignContent: 'right',
            tooltip: statusTooltip,
        },
        {
            header: t('coInnovationFunds.fundsManagement.actions'),
            accessorKey: 'actions',
            cellType: 'actions',
            tooltip: actionsTooltip,
            size: 8,
            visible: validateFundPermission,
            actions: getFundActions(),
        },
    ];

    function getFundActions() {
        return [
            createAction({
                permission: canValidateChangeRequestPermissions,
                iconColor: 'text-green-600',
                iconType: ApproveIcon,
                tooltipText: t('coInnovationFunds.validationFundsForm.approve'),
                actionType: 'approve',
                statusCheck: fundStatuses.pending,
            }),
            createAction({
                permission: canValidateChangeRequestPermissions,
                iconColor: 'text-red-600',
                iconType: RejectIcon,
                tooltipText: t('coInnovationFunds.validationFundsForm.reject'),
                actionType: 'reject',
                statusCheck: fundStatuses.pending,
            }),
            createAction({
                permission: canChangeRequestPermissions,
                iconColor: 'text-blue-800',
                iconType: EditIcon,
                tooltipText: t('coInnovationFunds.validationFundsForm.requestChange'),
                actionType: 'changeRequest',
                statusCheck: fundStatuses.approved,
            }),
            createAction({
                permission: canValidateChangeRequestPermissions,
                iconColor: 'text-green-600',
                iconType: ApproveIcon,
                tooltipText: t('coInnovationFunds.validationFundsForm.approve'),
                actionType: 'approveCR',
                statusCheck: fundStatuses.changeRequestPending,
            }),
            createAction({
                permission: canValidateChangeRequestPermissions,
                iconColor: 'text-red-600',
                iconType: RejectIcon,
                tooltipText: t('coInnovationFunds.validationFundsForm.reject'),
                actionType: 'rejectCR',
                statusCheck: fundStatuses.changeRequestPending,
            }),
        ];
    }

    function createAction({
        permission,
        iconColor,
        iconType: IconType,
        tooltipText,
        actionType,
        statusCheck,
    }: ActionParams) {
        return {
            icon: (
                <HasPermission neededPermissions={permission}>
                    <span className={`${iconColor}`}>
                        <IconType title={actionType} data-tooltip-id={actionType} data-testid="tooltip-icon" />
                        <ReactTooltip id={actionType} className="tooltip" offset={2}>
                            {tooltipText}
                        </ReactTooltip>
                    </span>
                </HasPermission>
            ),
            rowAccessorKey: 'id',
            onClick: (id: string | number) => setSelectedFundState({ id: id.toString(), types: [actionType] }),
            hide: (id: string | number) => !permission || getItemStatusId(funds, id.toString()) !== statusCheck,
        };
    }

    const { sorting } = sort;
    const isLoading = isFetching;
    const { filter } = useStore(state => state.coInnovationFunds);
    const isFiltered = checkIfFundFiltersAreActive(filter);

    return isMobile ? (
        <FundMobileTable
            data={tableData}
            setSelectedFundState={setSelectedFundState}
            pagination={{ pageIndex, pageSize }}
            totalItems={funds?.total || 0}
            firstItemIndex={firstItemIndex}
            lastItemIndex={lastItemIndex}
            pageCount={pageCount}
            paginationCallback={setPagination}
        />
    ) : (
        <div className="&_table_td:first-child_div]:text-left">
            <Table
                columns={columns}
                totalItems={funds?.total}
                data={tableData}
                caption={t('coInnovationFunds.navigation.fundsManagement')}
                sorting={sorting}
                sortingCallback={sorting => setSort(previousState => ({ ...previousState, sorting }))}
                isLoading={isLoading}
                pagination={{ pageIndex, pageSize }}
                paginationCallback={setPagination}
                isFiltered={isFiltered}
                firstItemIndex={firstItemIndex}
                lastItemIndex={lastItemIndex}
                pageCount={pageCount}
            />
        </div>
    );
};
