import classNames from 'classnames';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { FilterType, ReportFilterType } from '../domain';

import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';
import { blue900Color } from '@shared/constants';
import { determineTruncatedString } from '@shared/formatting/formatString';

type Props = {
    listItem: FilterType;
    toggleTagCallback: (listItem: FilterType, tagType: ReportFilterType) => void;
    isActive: boolean;
    type: ReportFilterType;
    bulletColor?: string;
    contact?: { assignedBlogsTotal?: number | undefined; id: number; name: string; photo?: string } | null | undefined;
    publicationDate?: { assignedBlogsTotal?: number | undefined; id: number; year: number } | null | undefined;
};

const getInitials = (fullName: string) => {
    if (!fullName) {
        return '';
    }
    const nameParts = fullName.split(' ');
    return nameParts
        .map(part => part.charAt(0))
        .join('')
        .toUpperCase();
};

const FilterListItem = ({ listItem, toggleTagCallback, bulletColor, isActive, contact, type }: Props) => {
    const isParent = (listItem.children && listItem.children.length > 0) || false;
    return (
        <div className="flex w-full justify-between py-0 pr-3 sm:py-[7px]">
            <div
                className={classNames({
                    isActive,
                    'cursor-pointer': !isActive && listItem.assingedToInitiative !== 0,
                })}
                onClick={() => listItem.assingedToInitiative > 0 && toggleTagCallback(listItem, type)}
            >
                <div
                    className={classNames(
                        {
                            'font-bold': isActive,
                        },
                        'flex items-center'
                    )}
                >
                    {bulletColor && (
                        <div className="px-[7px] py-[1px]">
                            <span
                                className={`inline-block h-3 w-[10px] ${
                                    listItem.assingedToInitiative === 0 ? 'bg-neutral-oak-30' : bulletColor
                                }`}
                                style={{
                                    clipPath: 'polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)',
                                }}
                            ></span>
                        </div>
                    )}
                    {!!contact && (
                        <div
                            className={classNames(
                                'mr-1 flex h-8 w-8 overflow-hidden rounded-full border border-blue-850',
                                {
                                    ' bg-white': !contact?.photo,
                                }
                            )}
                        >
                            {contact?.photo ? (
                                <img
                                    src={`data:image/jpg;base64,${contact?.photo}`}
                                    alt="avatar image"
                                    className={classNames({
                                        'h-full max-w-none': contact?.photo,
                                    })}
                                />
                            ) : (
                                <p className="flex h-full w-full items-center justify-center text-2xs font-semibold">
                                    {getInitials(contact?.name ?? '')}
                                </p>
                            )}
                        </div>
                    )}
                    <span
                        className={classNames('whitespace-nowrap text-left', {
                            'text-oak-30': listItem.assingedToInitiative === 0,
                        })}
                        data-tooltip-id={
                            listItem.value !==
                            determineTruncatedString(listItem.value, isParent, listItem.assingedToInitiative).replace(
                                /\.{3}$/,
                                ''
                            )
                                ? listItem.value
                                : undefined
                        }
                    >
                        {determineTruncatedString(listItem.value, isParent, listItem.assingedToInitiative)}
                    </span>

                    {listItem.value !==
                        determineTruncatedString(listItem.value, isParent, listItem.assingedToInitiative).replace(
                            /\.{3}$/,
                            ''
                        ) && (
                        <ReactTooltip
                            id={listItem.value}
                            className="tooltip"
                            style={{
                                whiteSpace: 'normal',
                                overflowWrap: 'break-word',
                                wordWrap: 'break-word',
                                textAlign: 'left',
                                overflow: 'visible',
                            }}
                        >
                            {listItem.value}
                        </ReactTooltip>
                    )}
                    <span
                        className={classNames('ml-1', {
                            'text-oak-30': listItem.assingedToInitiative === 0,
                            'text-blue-850': listItem.assingedToInitiative > 0,
                        })}
                    >
                        ({listItem.assingedToInitiative ?? 0})
                    </span>
                </div>
            </div>
            {isActive && (
                <div
                    className="ml-2 flex cursor-pointer items-center justify-end"
                    onClick={() => toggleTagCallback(listItem, type)}
                >
                    <CloseIcon color={blue900Color} />{' '}
                </div>
            )}
        </div>
    );
};

export { FilterListItem };
