import { z } from 'zod';

import { emptyUUID } from '@shared/constants';
import { SortingProps } from '@shared/ui/sorting';

export const validatorListParser = z.object({
    total: z.number().min(0).optional(),
    items: z.array(
        z.object({
            id: z
                .string()
                .uuid()
                .refine(validation => validation !== emptyUUID),
            name: z.string(),
        })
    ),
});

export const commentWithoutContactParser = z.object({
    id: z
        .string()
        .uuid()
        .refine(validation => validation !== emptyUUID),
    text: z.string().min(1),
    creationDate: z.string().refine(val => z.coerce.date().safeParse(val).success),
});

const contactParser = z.object({
    id: z
        .string()
        .uuid()
        .refine(validation => validation !== emptyUUID),
    name: z.string(),
    email: z.string(),
    photo: z.string().optional(),
});

export const coInnovationFundListItemParser = z.object({
    id: z
        .string()
        .uuid()
        .refine(validation => validation !== emptyUUID),
    fundName: z.string().min(0).max(100),
    partner: z.object({
        id: z
            .string()
            .uuid()
            .refine(validation => validation !== emptyUUID),
        name: z.string().min(1),
    }),
    provided: z.number().gte(0),
    carryForward: z.number().min(0).gte(0),
    expired: z.number().gte(0),
    available: z.number().optional(),
    beginDate: z.string().refine(val => z.coerce.date().safeParse(val).success),
    consumptionExpirationDate: z.string().refine(val => z.coerce.date().safeParse(val).success),
    statusId: z.number(),
    submissionDate: z.string().refine(val => z.coerce.date().safeParse(val).success),
    submittedByContact: contactParser,
});

export const changeRequestFlowParserParser = z.object({
    changeRequestStatusId: z.number().min(0),
    comment: z.string().optional(),
    creationDate: z
        .string()
        .refine(val => z.coerce.date().safeParse(val).success)
        .optional(),
    submittedByContact: contactParser,
    validatedByContact: contactParser.optional(),
    submissionComment: z.string().optional(),
    submissionDate: z.string().refine(val => z.coerce.date().safeParse(val).success),
    validationDate: z
        .string()
        .refine(val => z.coerce.date().safeParse(val).success)
        .optional(),
    validationComment: z.string().optional(),
});

export const coInnovationFundParser = coInnovationFundListItemParser.extend({
    sponsors: z.array(contactParser).optional(),
    validationDate: z
        .string()
        .refine(val => z.coerce.date().safeParse(val).success)
        .optional(),
    validatedByContact: contactParser.optional(),
    submissionComment: commentWithoutContactParser.optional(),
    validationComment: commentWithoutContactParser.optional(),
    changeRequests: z.array(changeRequestFlowParserParser).optional(),
});

export const coInnovationFundListResponseParser = z.object({
    total: z.number().min(0),
    items: z.array(coInnovationFundParser),
    totalProvided: z.number().min(0),
    totalAvailable: z.number(),
    totalExpired: z.number().min(0),
});

export const changeRequestParser = z.object({
    provided: z.number().min(0),
    expired: z.number().min(0),
    carriedForward: z.number().min(0),
    submittedByContact: z.string(),
    partner: z.string(),
    comment: z.string().optional(),
    submissionDate: z.string().refine(val => z.coerce.date().safeParse(val).success),
});

const coInnovationFundsSummaryAddFundsParser = z.object({
    partnerId: z.string(),
    fundName: z.string(),
    provided: z.string(),
    carryForward: z.string(),
    beginDate: z.date(),
    consumptionDeadline: z.date(),
    submissionComment: z.string(),
});

const coInnovationFundValidationParamParser = z.object({
    id: z.string(),
    comment: z.string(),
    fundStatusId: z.number(),
});

const coInnovationFundChangeRequestParamParser = z.object({
    fundId: z.string(),
    provided: z.string(),
    expired: z.string(),
    carriedForward: z.string(),
    comment: z.string(),
});

const coInnovationChangeRequestValidationParamParser = z.object({
    id: z.string(),
    approved: z.boolean(),
    comment: z.string(),
});

export type CoInnovationFundListItem = z.infer<typeof coInnovationFundListItemParser>;
export type CoInnovationFund = z.infer<typeof coInnovationFundParser>;
export type CoInnovationFundListResponse = z.infer<typeof coInnovationFundListResponseParser>;
export type ValidatorsListResponse = z.infer<typeof validatorListParser>;
export type ChangeRequest = z.infer<typeof changeRequestParser>;
export type CoInnovationFundsSummaryAddFunds = z.infer<typeof coInnovationFundsSummaryAddFundsParser>;
export type CoInnovationFundChangeRequestParam = z.infer<typeof coInnovationFundChangeRequestParamParser>;
export type CoInnovationFundValidationParam = z.infer<typeof coInnovationFundValidationParamParser>;
export type CoInnovationChangeRequestValidationParam = z.infer<typeof coInnovationChangeRequestValidationParamParser>;

export type FundFilterFormState = {
    from: string;
    to: string;
    partners: string[];
    approvers: { value: string; label: string }[];
    statuses: { value: number; label: string }[];
};

export type SortParams = {
    sorting: SortingProps;
};

export type GetFundsRequest = {
    partnerIds: string[];
    statusIds: string[];
    validatorIds: string[];
    beginDateFrom: string | undefined;
    beginDateTo: string | undefined;
};
