import { useMemo, useCallback } from 'react';

import { GetInitiativesFilterParam, KeyValuePair, ReportFilterType } from '../domain';
import { FilterCategory, InitiativesFilter } from '../filter/InitiativesFilter';
import { InitiativesFilterType } from '../types';

type InitiativesFilterContentProps = {
    filters: InitiativesFilterType;
    listParams: GetInitiativesFilterParam;
    setListParams: React.Dispatch<React.SetStateAction<GetInitiativesFilterParam>>;
};

const InitiativesFilterContent = ({ filters, listParams, setListParams }: InitiativesFilterContentProps) => {
    const categories = useMemo<FilterCategory[]>(
        () => [
            { type: ReportFilterType.EmergingTech, isOpen: true, options: filters?.emergingTechnologies || [] },
            { type: ReportFilterType.Step, isOpen: true, options: filters?.steps || [] },
            { type: ReportFilterType.Status, isOpen: true, options: filters?.statuses || [] },
            { type: ReportFilterType.ItStreams, isOpen: true, options: filters?.itStreams || [] },
            { type: ReportFilterType.Partners, isOpen: true, options: filters?.partners || [] },
            { type: ReportFilterType.Contacts, isOpen: true, options: filters?.contacts || [] },
        ],
        [filters]
    );

    const toggleCategory = useCallback(
        (categoryType: ReportFilterType) => {
            setListParams(prevParams => ({
                ...prevParams,
                [categoryType]: prevParams[categoryType],
            }));
        },
        [setListParams]
    );

    const toggleTagCallback = useCallback(
        (listItem: KeyValuePair, tagType: ReportFilterType) => {
            setListParams(prevParams => ({
                ...prevParams,
                [tagType]: prevParams[tagType].find(x => x.key === listItem.key)
                    ? prevParams[tagType].filter(x => x.key !== listItem.key)
                    : [...prevParams[tagType], listItem],
            }));
        },
        [setListParams]
    );

    return (
        <InitiativesFilter
            filterCategories={categories}
            listParams={listParams}
            toggleCategory={toggleCategory}
            toggleTagCallback={toggleTagCallback}
        />
    );
};

export { InitiativesFilterContent };
