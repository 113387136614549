import { TFunction } from 'i18next';
import { Link } from 'react-router-dom';

import { Report } from '../../../reportGenaiUseCases/domain';

import { InitiativeCardDetailRow } from './InitiativeCardDetailRow';
import { InitiativeCardHeader } from './InitiativeCardHeader';
import { InitiativeCardTags } from './InitiativeCardTags';

import { ReactComponent as PartnerLogo } from '@assets/icons/hands.svg';
import { ReactComponent as TeamLogo } from '@assets/icons/manDays.svg';
import { ReactComponent as ContactLogo } from '@assets/icons/user.svg';

type Props = {
    initiative: Report;
    emergingTechObj: { [key: string]: string[] };
    t: TFunction;
};

const truncateString = (str: string, maxLength: number) => {
    if (str.length <= maxLength) {
        return str;
    }

    const truncatedStr = str.substring(0, maxLength);
    const lastSpaceIndex = truncatedStr.lastIndexOf(' ');

    return lastSpaceIndex === -1 ? truncatedStr + ' ...' : truncatedStr.substring(0, lastSpaceIndex) + ' ...';
};

export const InitiativeCard = ({ initiative, emergingTechObj, t }: Props) => {
    const { name, contacts, itStream, partners, tags, id, status } = initiative;
    const itTeams = itStream?.value ? itStream.value.split(',') : [];

    return (
        <Link className="flex justify-center hover:shadow-xl" to={id}>
            <div className="flex h-full w-full flex-1 flex-col rounded p-6 shadow-elevations">
                <div className=" mb-4 mt-2">
                    <InitiativeCardHeader step={initiative.initiativeStatus} t={t} status={status} />
                </div>
                <div className="mb-4 flex h-[36px]">
                    <p className="font-bold">{truncateString(name, 50)}</p>
                </div>
                <div className="mb-4 flex min-h-[6rem] flex-col gap-3">
                    <InitiativeCardDetailRow values={itTeams}>
                        <TeamLogo className="h-[24px] w-[24px] p-1" />
                    </InitiativeCardDetailRow>
                    <InitiativeCardDetailRow
                        values={contacts.map(contact => `${contact.firstName} ${contact.lastName}`)}
                    >
                        <ContactLogo className="h-[24px] w-[24px]" />
                    </InitiativeCardDetailRow>
                    <InitiativeCardDetailRow values={partners.map(partner => partner.value ?? '')}>
                        <PartnerLogo className="h-[24px] w-[24px]" />
                    </InitiativeCardDetailRow>
                </div>
                <InitiativeCardTags emergingTechObj={emergingTechObj} tags={tags} />
            </div>
        </Link>
    );
};
