import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { getTopContentPage } from './api';
import { DiscoveryCardSection } from './DiscoveryCardSection';
import { TopContentItem } from './domain';
import TopContent from './TopContent';

import { namespaces } from '@shared/constants';
import { Spinner } from '@shared/ui';
import { SegmentedButton } from '@shared/ui/segmentedButton/SegmentedButton';

const Discovery = () => {
    const { t } = useTranslation(namespaces.features.discovery);
    const [topContentData, setTopContentData] = useState<TopContentItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getTopContentPage();
                setTopContentData(data);
            } catch (error) {
                console.error('Error fetching top content data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <section id="discovery">
            {loading ? (
                <div className="flex h-[700px] w-full flex-grow items-center justify-center">
                    <Spinner />
                </div>
            ) : (
                <>
                    <div className="bg-neutral-oak-5 px-[200px] pt-header-height">
                        <div className="mb-9 flex flex-col items-center">
                            <div className="w-full max-w-[750px] pt-6">
                                <SegmentedButton
                                    options={[
                                        { name: 'Discovery', url: 'discovery', selected: true },
                                        { name: 'Ideation', url: 'ideation', selected: false },
                                        { name: 'Initiatives', url: 'initiatives', selected: false },
                                    ]}
                                ></SegmentedButton>
                            </div>
                            <div className="inline-flex">
                                <span className="text-[12px] leading-5 text-neutral-oak-100">
                                    {t('funnel-text.normal')}&nbsp;
                                </span>
                                <span className="text-[12px] font-bold leading-5 text-neutral-oak-100">
                                    {t('funnel-text.bold')}
                                </span>
                            </div>{' '}
                        </div>
                        <DiscoveryCardSection></DiscoveryCardSection>
                    </div>
                    <div className="mx-16">
                        <TopContent
                            topContentData={topContentData.map(item => ({
                                ...item,
                            }))}
                        />
                    </div>
                </>
            )}
        </section>
    );
};

export { Discovery };
