import { CoInnovationFund } from './domain';
import FundDetailComment from './FundDetailComment';

enum CommentContext {
    Pending = 'coInnovationFunds.fundsManagement.statuses.pending',
    Approved = 'coInnovationFunds.fundsManagement.statuses.approved',
    Rejected = 'coInnovationFunds.fundsManagement.statuses.rejected',
    Canceled = 'coInnovationFunds.fundsManagement.statuses.canceled',
    ChangeRequestPending = 'coInnovationFunds.fundsManagement.statuses.cRPending',
}

type FundCommentsSectionProps = {
    data: CoInnovationFund;
};

function FundCommentsSection({ data }: FundCommentsSectionProps) {
    return (
        <div className="flex max-h-fit flex-col gap-3.5 overflow-auto pb-4 pl-7">
            {data?.submissionComment && (
                <FundDetailComment
                    key={data.submissionDate}
                    name={data.submittedByContact.name}
                    date={data.submissionDate}
                    email={data.submittedByContact.email}
                    text={data.submissionComment.text}
                    photo={data.submittedByContact.photo}
                    commentContext={CommentContext.Pending}
                />
            )}
            {data?.validationComment && data.validatedByContact && (
                <FundDetailComment
                    key={data.validationDate}
                    name={data.validatedByContact.name}
                    email={data.validatedByContact.email}
                    date={data.validationDate}
                    text={data.validationComment.text}
                    photo={data.validatedByContact.photo}
                    commentContext={[1, 4].includes(data.statusId) ? CommentContext.Approved : CommentContext.Rejected}
                />
            )}
            {data?.changeRequests?.map(x => (
                <div className="flex flex-col gap-3.5" key={x.submissionDate}>
                    {x.submissionComment && (
                        <FundDetailComment
                            key={x.creationDate}
                            name={x.submittedByContact?.name}
                            email={x.submittedByContact?.email}
                            date={x.submissionDate}
                            text={x.submissionComment}
                            photo={x.submittedByContact.photo}
                            commentContext={CommentContext.ChangeRequestPending}
                        />
                    )}
                    {x.validationComment && x.validatedByContact && (
                        <FundDetailComment
                            key={x.validationDate}
                            name={x.validatedByContact?.name}
                            email={x.validatedByContact?.email}
                            date={x.validationDate}
                            text={x.validationComment}
                            photo={x.validatedByContact.photo}
                            commentContext={
                                x.changeRequestStatusId === 1 ? CommentContext.Approved : CommentContext.Rejected
                            }
                        />
                    )}
                </div>
            ))}
        </div>
    );
}

export default FundCommentsSection;
