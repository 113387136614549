import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { fundStatuses } from './constants';
import { PanelStatusDetail } from './Detail/PanelStatusDetail';
import { PanelStatusDetailRequestChange } from './Detail/PanelStatusDetailRequestChange';
import { TopDetail } from './Detail/TopDetail';
import FundCommentsSection from './FundCommentsSection';
import { FundDetailSection } from './FundDetailSection';
import FundStatusFlow from './FundDetailStatusFlow';
import { useGetFundByIdQuery } from './useGetFundByIdQuery';

import { HasPermission } from '@features/authorization/HasPermission';
import { permissions } from '@features/authorization/permissions';
import { namespaces } from '@shared/constants';
import { BreadCrums } from '@shared/ui/breadCrums';

const canViewFundDetailPermissions = [permissions.coInnovationFunds.fundsManagement.view];

export const FundDetail = () => {
    const { fundId } = useParams<{ fundId?: string }>();
    const { data: fund } = useGetFundByIdQuery(fundId ?? '');
    const { t } = useTranslation(namespaces.features.coInnovationFunds);
    const [initialPage, setInitialPage] = useState();

    useEffect(() => {
        const savedPagination = sessionStorage.getItem('fundTablePagination');
        if (savedPagination) {
            const paginationObject = JSON.parse(savedPagination);
            const pageNumber = paginationObject.page;
            setInitialPage(pageNumber);
        }
    }, []);

    return (
        <div className="flex flex-col" data-testid="fund-detail">
            <BreadCrums
                breadcrums={[
                    { name: 'Funds Management', link: `../funds-management?page=${initialPage}` },
                    { name: 'Fund detail page' },
                ]}
            />

            <HasPermission neededPermissions={canViewFundDetailPermissions}>
                <div className="mb-20 flex w-full flex-col">
                    {fund && <TopDetail data={fund} />}

                    {fund?.statusId === fundStatuses.changeRequestPending ? (
                        <PanelStatusDetailRequestChange fund={fund} lastStatus={fundStatuses.approved} />
                    ) : (
                        <PanelStatusDetail fund={fund} />
                    )}

                    <div className="flex flex-1 flex-col px-8 pb-6 pt-6 md:px-8 ">
                        <div className="px-4"></div>
                        <div className="flex max-h-[60vh] min-h-[400px] flex-grow flex-col gap-2 overflow-hidden md:flex-row md:gap-4">
                            {fund && (
                                <>
                                    <FundDetailSection title={t('coInnovationFunds.fundsManagement.statusFlow')}>
                                        <FundStatusFlow data={fund} />
                                    </FundDetailSection>
                                    <FundDetailSection title={t('coInnovationFunds.fundsManagement.comments')}>
                                        <FundCommentsSection data={fund} />
                                    </FundDetailSection>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </HasPermission>
        </div>
    );
};
