import { useTranslation } from 'react-i18next';

import { useSingleProjectDetailsQuery } from './useSingleProjectDetails';

import { namespaces } from '@shared/constants';
import { formatNumber } from '@shared/formatting/formatNumber';
import { BoxItem, ContactBox } from '@shared/ui';

type Props = {
    id: string;
};

const SingleProjectDetailedView = ({ id }: Props) => {
    const { data } = useSingleProjectDetailsQuery(id);
    const { t } = useTranslation(namespaces.features.coInnovationFunds);
    if (!data) {
        return <></>;
    }
    const { phase, partner, horizon, contact, consumed, allocated, globalTeam, team, description, businessValue } =
        data;
    return (
        <div className="py-4">
            <h3 className="mb-[6px] text-base">{t('coInnovationFunds.detail.projectsDetails.projectDescription')}</h3>
            <p className="mb-8 text-sm font-light">{description}</p>

            <div className="mb-4 grid grid-cols-2 rounded bg-blue-100 p-4">
                {[
                    {
                        title: t('coInnovationFunds.detail.projectsDetails.allocated'),
                        value: `${formatNumber(allocated)} CHF`,
                    },
                    {
                        title: t('coInnovationFunds.detail.projectsDetails.consumed'),
                        value: `${formatNumber(consumed)} CHF`,
                    },
                ].map(({ title, value }, index) => (
                    <BoxItem key={index} title={title} value={value} />
                ))}
            </div>

            <div className="mb-3 grid grid-cols-2 gap-y-5 rounded py-3 md:grid-cols-3">
                <BoxItem title={t('coInnovationFunds.detail.projectsDetails.partner')} value={partner} />

                <BoxItem title={t('coInnovationFunds.detail.projectsDetails.horizon')} value={horizon} />

                <BoxItem
                    title={t('coInnovationFunds.detail.projectsDetails.phase.title')}
                    value={phase}
                    tooltipContent={t(
                        `coInnovationFunds.detail.projectsDetails.phase.${phase.toLocaleString().toLocaleLowerCase()}`
                    )}
                />
            </div>

            {businessValue ? (
                <>
                    <h3 className="mb-[6px] text-base">
                        {t('coInnovationFunds.detail.projectsDetails.businessValue')}
                    </h3>
                    <p className="mb-8 text-sm font-light">{businessValue}</p>
                </>
            ) : null}

            <div className="mb-6 grid grid-cols-1 gap-6 rounded md:grid-cols-2 ">
                {[
                    { title: t('coInnovationFunds.detail.projectsDetails.globalTeam'), value: globalTeam },
                    { title: t('coInnovationFunds.detail.projectsDetails.nestleTeam'), value: team },
                ].map(({ title, value }, index) => (
                    <BoxItem key={index} title={title} value={value} />
                ))}
            </div>

            {contact ? (
                <div>
                    <h3 className="mb-[6px] text-base">
                        {t('coInnovationFunds.detail.projectsDetails.nestleContact')}
                    </h3>
                    <ContactBox {...contact} showMailIcon={false} />
                </div>
            ) : null}
        </div>
    );
};

export { SingleProjectDetailedView };
