﻿import { useState, useEffect } from 'react';

import { ReactComponent as SearchIcon } from '@assets/icons/searchBarIcon.svg';

type Props = {
    title: string;
    onTitleChange: (title: string) => void;
};

export const Search = ({ title, onTitleChange }: Props) => {
    const [isActive, setIsActive] = useState(false);
    const [debouncedTitle, setDebouncedTitle] = useState(title);

    // Synchronize debouncedTitle with the title prop when it changes
    useEffect(() => {
        setDebouncedTitle(title);
    }, [title]);

    // Debounce logic for onTitleChange
    useEffect(() => {
        const handler = setTimeout(() => {
            onTitleChange(debouncedTitle);
        }, 300);

        return () => {
            clearTimeout(handler);
        };
    }, [debouncedTitle, onTitleChange]);

    const handleInputChange = (value: string) => {
        setDebouncedTitle(value);
    };

    return (
        <div
            data-testid="search-wrapper"
            className={`flex h-8 items-center rounded border p-1 ${
                isActive
                    ? 'border-basic-blue'
                    : 'border-neutral-oak-30 focus-within:border-primary-blue-30 hover:border-primary-blue-30'
            }`}
        >
            {/* Search Icon */}
            <SearchIcon data-testid="search-icon" width={24} height={24} className="text-basic-blue" />

            {/* Search Input */}
            <input
                data-testid="search-input"
                type="text"
                placeholder="Search"
                value={debouncedTitle}
                onChange={e => handleInputChange(e.target.value)}
                onFocus={() => setIsActive(true)}
                onBlur={() => setIsActive(false)}
                className="body-s-book w-full px-1 py-1 placeholder-neutral-oak-dark"
            />
        </div>
    );
};

export default Search;
