import { useState } from 'react';

import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';
import { ReactComponent as Union } from '@assets/icons/Union.svg';

interface DetailTooltipProps {
    title: string;
    description: string;
    IconComponent?: React.FC;
    onClick?: () => void;
}

const DetailTooltip: React.FC<DetailTooltipProps> = ({ title, description, IconComponent = Union, onClick }) => {
    const [isVisible, setIsVisible] = useState(true);

    const handleRedirect = () => {
        if (onClick) {
            onClick();
        }
    };

    const handleClose = () => {
        setIsVisible(false);
    };

    if (!isVisible) {
        return null;
    }

    return (
        <div className="absolute top-0 right-0 z-[101] flex h-[92px] w-[552px] translate-y-[80%] translate-x-[37%] rounded border-[1px] border-primary-blue-30 bg-white p-3 shadow-elevations">
            <div className="absolute top-[-6px] left-1/2 h-3 w-3 rotate-45 border border-primary-blue-30 border-b-white border-r-white bg-white"></div>
            <div className="flex gap-3 rounded" onClick={handleRedirect}>
                <div>
                    <IconComponent className="h-16 w-16  bg-neutral-oak-5 p-3" />
                </div>
                <div>
                    <h4 className="body-m-bold text-basic-blue">{title}</h4>
                    <p className="body-s-book mt-3">{description}</p>
                </div>
            </div>
            <div className="cursor-pointer justify-end px-1  text-basic-blue" onClick={handleClose}>
                <CloseIcon aria-label="close icon" />
            </div>
        </div>
    );
};

export { DetailTooltip };
