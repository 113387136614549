export const formatDate = (value: string | Date) => {
    const validationDate = new Date('01/01/1970');
    const result = new Date(value);
    if (result < validationDate) {
        return 'unknown';
    }
    return result.toLocaleDateString('en-GB');
};

export const formatDateWithoutSpecialCharacters = (value: string | Date) => {
    const validationDate = new Date('01/01/1970');
    const result = new Date(value);
    if (result < validationDate) {
        return 'unknown';
    }
    const day = result.toLocaleString('en-GB', { day: '2-digit' });
    const month = result.toLocaleString('en-GB', { month: '2-digit' });
    const year = result.getFullYear();

    return `${day}${month}${year}`;
};

export const formatDateMonthYear = () => {
    const date = new Date();
    const month = date.toLocaleString('en-GB', { month: 'long' });
    const year = date.getFullYear();

    return `${month} ${year}`;
};

export const formatDateWithHour = (value: string | Date) => {
    const date = new Date(value);
    const dateString = date.toLocaleDateString('en-GB'); // Adjust the locale as needed
    const timeString = date.toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    });

    return `${dateString} - ${timeString}`;
};

export const formatUTC = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'UTC',
        timeZoneName: 'shortOffset',
        hour12: false,
    };

    const utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

    const dateTimeFormat = new Intl.DateTimeFormat('en-US', options);
    const parts = dateTimeFormat.formatToParts(utcDate);

    const day = parts.find(part => part.type === 'day')?.value;
    const month = parts.find(part => part.type === 'month')?.value?.toUpperCase();
    const year = parts.find(part => part.type === 'year')?.value;
    const hour = parts.find(part => part.type === 'hour')?.value;
    const minute = parts.find(part => part.type === 'minute')?.value;

    return `${day} ${month} ${year} ${hour}:${minute}`;
};
