import { z } from 'zod';

import { GlobalTeamTypes, HorizonTypes, PhaseTypes } from '../domain';

import { emptyUUID } from '@shared/constants';

export type FilterTypes = 'horizons' | 'teams' | 'partners' | 'globalTeams' | 'years' | 'phases' | 'projectName';
export type FilterForms = 'summaryFilter' | 'detailFilter' | 'fundsFilter';

export type CoInnovationFilter = {
    projectName: string;
    horizons: HorizonTypes[];
    globalTeams: GlobalTeamTypes[];
    partners: string[];
    teams: string[];
    years: number[];
    phases: PhaseTypes[];
    from: string | undefined;
    to: string | undefined;
    statuses: { value: number; label: string }[];
    approvers: { value: string; label: string }[];
};

export enum PageType {
    Summary = 'Summary',
    Fund = 'Fund',
    Details = 'Details',
}

export const emptyFilterValue: CoInnovationFilter = {
    globalTeams: [],
    partners: [],
    horizons: [],
    teams: [],
    years: [],
    phases: [],
    projectName: '',
    from: undefined,
    to: undefined,
    statuses: [],
    approvers: [],
};

export const anyDateFilterByPage = (page: PageType, { years, from, to }: CoInnovationFilter) =>
    page === 'Fund' ? from !== undefined || to !== undefined : years.length > 0;

export const anyFilter = ({
    globalTeams,
    horizons,
    partners,
    teams,
    years,
    phases,
    projectName,
    from,
    to,
    approvers,
    statuses,
}: CoInnovationFilter) =>
    horizons.length > 0 ||
    globalTeams.length > 0 ||
    partners.length > 0 ||
    teams.length > 0 ||
    years.length > 0 ||
    phases.length > 0 ||
    projectName.trim().length > 0 ||
    from !== undefined ||
    to !== undefined ||
    statuses.length > 0 ||
    approvers.length > 0;

export const anyFilterByPage = (
    page: PageType,
    {
        globalTeams = [],
        horizons = [],
        partners = [],
        teams = [],
        years = [],
        phases = [],
        projectName = '',
        from,
        to,
        approvers = [],
        statuses = [],
    }: CoInnovationFilter
): boolean => {
    const checkByPage = {
        Summary: () => years.length > 0,
        Details: () =>
            globalTeams.length > 0 ||
            horizons.length > 0 ||
            phases.length > 0 ||
            teams.length > 0 ||
            projectName.trim().length > 0 ||
            years.length > 0,
        Fund: () => from !== undefined || to !== undefined || approvers.length > 0 || statuses.length > 0,
    };

    if (partners.length > 0) {
        return true;
    }

    return checkByPage[page]();
};

export const coInnovationMasterDataWithUuidIdentifier = z.array(
    z.object({
        id: z
            .string()
            .uuid()
            .refine(validation => validation !== emptyUUID),
        name: z.string().min(1),
        parentPartnerName: z.string().optional(),
    })
);

export const coInnovationMasterDataPartner = z.array(
    z.object({
        id: z
            .string()
            .uuid()
            .refine(validation => validation !== emptyUUID),
        name: z.string().min(1),
        abbreviation: z.string().min(1),
        parentPartnerName: z.string().optional(),
    })
);

export const coInnovationMasterDataWithIntIdentifier = z.array(
    z.object({
        id: z.number().int().gte(-1),
        name: z.string().min(1),
    })
);
export const coInnovationFundYearsListResponseParser = z.array(
    z.object({
        year: z.number(),
    })
);

export const coInnovationMasterDataWithIntIdentifierAndIntValue = z.array(
    z.object({
        id: z.number().int().gte(-1),
        name: z.number().int().gte(-1),
    })
);

export type CoInnovationMasterDataWithUuidIdentifier = z.infer<typeof coInnovationMasterDataWithUuidIdentifier>;

export type CoInnovationMasterDataWithIntIdentifier = z.infer<typeof coInnovationMasterDataWithIntIdentifier>;

export type CoInnovationFundYearsListResponseParser = z.infer<typeof coInnovationFundYearsListResponseParser>;

export type CoInnovationMasterDataPartner = z.infer<typeof coInnovationMasterDataPartner>;
