import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import HomeImage from '@assets/images/preLanding.png';
import { namespaces } from '@shared/constants';

const PartnersLanding = () => {
    const { t } = useTranslation(namespaces.features.landing);
    return (
        <div className="flex px-[200px] pt-[140px]">
            <section className="mx-auto flex items-center py-4">
                <div className="relative -mr-24 flex flex-1 flex-col gap-12">
                    <div className="flex flex-1 flex-col gap-6">
                        <h3 className="text-[4rem] font-bold leading-[4.5rem]">
                            {t('partner.title')}
                            <span className="font-nestleBrush font-normal text-primary-blue-100">
                                {t('partner.titleBold')}
                            </span>
                        </h3>
                        <p className="border-b border-neutral-oak-30 pb-12 text-xl font-normal leading-8">
                            {t('partner.description')}
                            <span className="font-bold">{t('partner.descriptionBold')}</span>
                        </p>
                    </div>
                    <div className="flex flex-1 flex-col gap-6">
                        <div className="flex flex-1 flex-col gap-3">
                            <h3 className="text-2xl font-bold">{t('partner.subTitle')}</h3>
                            <p className="text-xl font-normal leading-8">
                                {t('partner.subdescriptionStart')}
                                <span className="font-bold">{t('partner.subdescriptionBold')}</span>
                                {t('partner.subdescriptionEnd')}
                            </p>
                        </div>
                        <Link
                            to="/co-innovation-funds"
                            className="self-start bg-primary-blue-100 px-14 py-2 font-normal text-white"
                        >
                            {t('partner.button')}
                        </Link>
                    </div>
                </div>
                <div className="flex-1">
                    <img src={HomeImage} alt="Discovery" />
                </div>
            </section>
        </div>
    );
};

export default PartnersLanding;
