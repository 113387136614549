import { useNavigate } from 'react-router-dom';
type Option = {
    name: string;
    url: string;
    selected: boolean;
};

type Props = {
    options: Option[];
};

const SegmentedButton = ({ options }: Props) => {
    const navigate = useNavigate();

    const handleClick = (page: string) => {
        navigate(`?page=${page}#section`);
    };

    return (
        <nav className="mb-4 w-full rounded bg-neutral-oak-15 p-[6px] text-neutral-oak-dark">
            <ul className="flex h-9 flex-row items-stretch justify-between gap-[6px]">
                {options.map(x =>
                    x.selected ? (
                        <li key={x.name} className="flex-1 content-center rounded bg-basic-blue text-secondary-white">
                            <span className="body-m-bold flex justify-center">{x.name}</span>
                        </li>
                    ) : (
                        <li key={x.name} className="flex-1 content-center rounded hover:bg-neutral-oak-30 ">
                            <span
                                className="flex h-full items-center justify-center rounded text-sm font-bold active:bg-basic-blue active:text-white"
                                onClick={() => {
                                    handleClick(x.url);
                                }}
                            >
                                {x.name}
                            </span>
                        </li>
                    )
                )}
            </ul>
        </nav>
    );
};

export { SegmentedButton };
