﻿import { GetInitiativesFilterParam } from '@features/initiatives/domain';

export const hasActiveFilters = (params: GetInitiativesFilterParam) => {
    return (
        params.emergingTechnologies.length > 0 ||
        params.steps.length > 0 ||
        params.statuses.length > 0 ||
        params.itStreams.length > 0 ||
        params.partners.length > 0 ||
        params.contacts.length > 0 ||
        Boolean(params.title?.trim())
    );
};
