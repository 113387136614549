import { useTranslation } from 'react-i18next';

import { ContentType } from './domain';
import { TopContentCard } from './TopContentCard';

import { namespaces } from '@shared/constants';

interface TopContentProps {
    topContentData: {
        title: string;
        authors?: string[];
        topics?: string[];
        contacts?: string[];
        step: string;
        status: string;
        type: ContentType;
        url: string;
    }[];
}

const TopContent: React.FC<TopContentProps> = ({ topContentData }) => {
    const { t } = useTranslation(namespaces.features.discovery);

    return (
        <div>
            <div className="mt-6 mb-2 font-nestle font-bold">{t('topContent.topContent')}</div>
            <div className="grid grid-cols-4 gap-3">
                {topContentData.map((item, index) => (
                    <TopContentCard key={index} {...item} type={item.type} />
                ))}
            </div>
        </div>
    );
};

export default TopContent;
