import { useTranslation } from 'react-i18next';

import { ReactComponent as MailIcon } from '@assets/icons/mail.svg';
import { namespaces } from '@shared/constants';
import { formatDateWithHour } from '@shared/formatting';
import { Contact } from '@shared/ui';

type CommentSectionProps = {
    name: string;
    text: string;
    date?: string;
    commentContext?: string;
    photo?: string;
    email: string;
};

function FundDetailComment({ name, text, date, commentContext, photo, email }: CommentSectionProps) {
    const { t } = useTranslation(namespaces.features.coInnovationFunds);

    const statusChangeKey =
        commentContext !== undefined ? commentContext || 'coInnovationFunds.fundsManagement.statuses.unknown' : '';
    const statusChangeText = ` ${t('coInnovationFunds.fundsManagement.changedTheStatusTo')} ${t(
        statusChangeKey
    ).toLowerCase()}`;
    const dateText = date !== undefined ? ` - ${formatDateWithHour(date)}` : '';
    const displayName = `${name}${statusChangeText}${dateText}`;

    return (
        <div className="flex flex-col gap-3.5" role="article" aria-label={`Comment by ${name}`}>
            <div className="flex">
                <Contact name={displayName} photo={photo} />
            </div>
            <div className="flex">
                <p className="body-s-light w-full text-left">{text}</p>
                <a role="link" className="justify-items-end pr-11 text-blue-800" href={`mailto:${email}`}>
                    <MailIcon />
                </a>
            </div>
        </div>
    );
}

export default FundDetailComment;
