import { DetailedHTMLProps, InputHTMLAttributes, ReactNode, forwardRef } from 'react';

import classNames from 'classnames';

import { InputErrors } from '../inputErrors';
import { Label } from '../label';

import { ReactComponent as EditIcon } from '@assets/icons/edit.svg';

type Props = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    containerClassName?: string;
    label?: ReactNode | undefined;
    suffix?: ReactNode | string;
    showEditIcon?: boolean | undefined;
    errors?: string[];
    disableSuffixPadding?: boolean;
};

const Input = forwardRef<HTMLInputElement, Props>(
    (
        { containerClassName, label, className, type, suffix, showEditIcon, errors, disableSuffixPadding, ...rest },
        ref
    ) => {
        return (
            <div className={classNames(containerClassName ? containerClassName : 'mb-[30px]')}>
                <Label id={rest.id}>{label}</Label>

                <div
                    className={classNames('flex rounded border', {
                        'border-error': !!errors,
                        'border-gray-200': !errors,
                    })}
                >
                    <input
                        type={type}
                        className={classNames(
                            className,
                            'block w-full grow rounded px-[6px] py-[6.5px] text-xs outline-none',
                            {
                                'text-gray-900': !errors,
                                'text-error': !!errors,
                            },

                            // remove the arrows from type="number"
                            '[&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:[-webkit-appearance:none]',
                            '[&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:[-webkit-appearance:none]',
                            type === 'number' ? '[-moz-appearance:textfield]' : null,
                            // place holder
                            '[&::-webkit-input-placeholder]:text-gray-900',
                            '[&:-moz-placeholder]:text-gray-900 [&:-moz-placeholder]:opacity-100',
                            '[&::-moz-placeholder]:text-gray-900 [&::-moz-placeholder]:opacity-100',
                            '[&:-ms-input-placeholder]:text-gray-900',
                            '[&::-ms-input-placeholder]:text-gray-900',
                            '[&::placeholder]:text-gray-900'
                        )}
                        {...rest}
                        ref={ref}
                    />
                    {showEditIcon ? (
                        <span
                            className={classNames(
                                'my-[8px] inline-flex items-center border-l border-blue-900 px-[6px] text-blue-900'
                            )}
                        >
                            <EditIcon />
                        </span>
                    ) : null}
                    {suffix ? (
                        <span className={classNames('inline-flex items-center', { 'p-[6px]': !disableSuffixPadding })}>
                            {suffix}
                        </span>
                    ) : null}
                </div>

                <InputErrors errors={errors} />
            </div>
        );
    }
);
Input.displayName = 'Input';

export { Input };
