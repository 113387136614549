import { Tooltip as ReactTooltip } from 'react-tooltip';

import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';
import { truncateString } from '@shared/formatting/formatString';
import { Button } from '@shared/ui';

type Props = {
    filterType: string;
    translationKey: string;
    value: string;
    text: string;
    bulletColor: string | undefined;
    onClickCallback: (filterType: string, value: string) => void;
};

const InititativesHeaderTag = ({ filterType, value, text, onClickCallback, bulletColor }: Props) => {
    return (
        <div className="category-container">
            <div className="tag-label flex h-7 items-center rounded-[4px] border border-blue-200 bg-white">
                {bulletColor && (
                    <span
                        className={`mr-2 inline-block h-3 w-3 border-blue-850 ${bulletColor}`}
                        style={{
                            clipPath: 'polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)',
                        }}
                    ></span>
                )}
                <span
                    data-tooltip-id={truncateString(text, 30).length < text.length ? text.substring(0, 20) : undefined}
                    className="flex-grow font-nestle text-xs"
                >
                    {truncateString(text, 30)}
                </span>
                <ReactTooltip
                    id={text.substring(0, 20)}
                    className={'tooltip'}
                    style={{
                        whiteSpace: 'normal',
                        overflowWrap: 'break-word',
                        wordWrap: 'break-word',
                        textAlign: 'left',
                        overflow: 'visible',
                    }}
                >
                    {text}
                </ReactTooltip>
                <Button
                    className="flex items-center"
                    data-testid="removeTagLabel"
                    onClick={() => onClickCallback(filterType, value)}
                >
                    <CloseIcon className="ml-2 h-4 w-4 text-blue-900" data-testid="closeIconTagLabel" />
                </Button>
            </div>
        </div>
    );
};

export { InititativesHeaderTag };
