import { useState, useRef, useEffect } from 'react';

import { ReactComponent as ChevronDown } from '@assets/icons/chevronDown.svg';
import { ReactComponent as ChevronUp } from '@assets/icons/chevronUp.svg';
import { ReactComponent as Tick } from '@assets/icons/tick.svg';

export type DropdownFilterOption<T> = {
    label: string;
    value: T;
    desc: boolean;
};

type DropdownFilterProps<T> = {
    options: DropdownFilterOption<T>[];
    selectedOption: DropdownFilterOption<T> | null;
    onSelect: (value: T, desc: boolean) => void;
    placeholder: string;
};

export const DropdownFilter = <T,>({ options, selectedOption, onSelect, placeholder }: DropdownFilterProps<T>) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleOutsideClick = (e: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    return (
        <div className="relative flex w-full items-center justify-end" ref={dropdownRef}>
            <button
                className="body-m-book flex items-center p-1 text-basic-blue"
                onClick={() => setIsOpen(prev => !prev)}
            >
                {selectedOption ? selectedOption.label : placeholder}
                {isOpen ? <ChevronUp className="ml-2 w-6" /> : <ChevronDown className="ml-2 w-6" />}
            </button>
            {isOpen && (
                <ul className="absolute right-0 top-full z-10 w-48 rounded-lg bg-white p-3 shadow-elevation-l">
                    <li className="body-s-book flex cursor-default items-center rounded-lg p-[6px] text-neutral-oak-60">
                        {placeholder}
                    </li>
                    {selectedOption && (
                        <li
                            className="body-m-book flex cursor-pointer items-center justify-between rounded-lg p-[6px] text-basic-blue"
                            onClick={() => {
                                onSelect(selectedOption.value, selectedOption.desc);
                                setIsOpen(false);
                            }}
                        >
                            {selectedOption.label}
                            <Tick className="fill-basic-blue" />
                        </li>
                    )}
                    {/* Render the rest of the options */}
                    {options
                        .filter(option => option.value !== selectedOption?.value)
                        .map(option => (
                            <li
                                key={String(option.value)}
                                className="body-m-book flex cursor-pointer items-center rounded-lg p-[6px] text-neutral-oak-dark hover:bg-neutral-oak-5"
                                onClick={() => {
                                    onSelect(option.value, option.desc);
                                    setIsOpen(false);
                                }}
                            >
                                {option.label}
                            </li>
                        ))}
                </ul>
            )}
        </div>
    );
};
