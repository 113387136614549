import { useQueries, useQuery } from '@tanstack/react-query';

import { getGlobalTeams, getHorizons, getPartners, getPhases, getTeams, getYears } from './api';
import {
    coInnovationFundYearsListResponseParser,
    coInnovationMasterDataPartner,
    coInnovationMasterDataWithIntIdentifier,
    coInnovationMasterDataWithUuidIdentifier,
} from './domain';

import { retrieveFromCache, saveToCache } from '@shared/caching';

const horizonsDataQueryKey = 'horizonsData';
const useHorizonsDataQuery = (disableSuspense = false) =>
    useQuery(
        [horizonsDataQueryKey],
        async () => {
            const response = await getHorizons();
            saveToCache(horizonsDataQueryKey, response);
            return response;
        },
        {
            suspense: !disableSuspense,
            placeholderData: () =>
                retrieveFromCache(horizonsDataQueryKey, coInnovationMasterDataWithIntIdentifier.parse),
        }
    );

const globalTeamsDataQueryKey = 'globalTeamsData';
const useGlobalTeamsDataQuery = () =>
    useQuery(
        [globalTeamsDataQueryKey],
        async () => {
            const response = await getGlobalTeams();
            saveToCache(globalTeamsDataQueryKey, response);
            return response;
        },
        {
            placeholderData: () =>
                retrieveFromCache(globalTeamsDataQueryKey, coInnovationMasterDataWithIntIdentifier.parse),
        }
    );

const phasesDataQueryKey = 'phasesData';
const usePhasesDataQuery = () =>
    useQuery(
        [phasesDataQueryKey],
        async () => {
            const response = await getPhases();
            saveToCache(phasesDataQueryKey, response);
            return response;
        },
        {
            placeholderData: () => retrieveFromCache(phasesDataQueryKey, coInnovationMasterDataWithIntIdentifier.parse),
        }
    );

const partnersDataQueryKey = 'partnersData';
const usePartnersDataQuery = (execute: boolean, suspense = true, excludeParents = false) =>
    useQuery(
        [partnersDataQueryKey + excludeParents],
        async () => {
            const response = await getPartners(excludeParents);
            saveToCache(partnersDataQueryKey, response);
            return response;
        },
        {
            enabled: execute,
            suspense,
            placeholderData: () => retrieveFromCache(partnersDataQueryKey, coInnovationMasterDataPartner.parse),
        }
    );

const teamsDataQueryKey = 'teamsData';
const useTeamsDataQuery = (execute: boolean) =>
    useQuery(
        [teamsDataQueryKey],
        async () => {
            const response = await getTeams();
            saveToCache(teamsDataQueryKey, response);
            return response;
        },
        {
            enabled: execute,
            placeholderData: () => retrieveFromCache(teamsDataQueryKey, coInnovationMasterDataWithUuidIdentifier.parse),
        }
    );

const yearsDataQueryKey = 'yearsData';
const useYearsDataQuery = (execute: boolean, suspense = true) =>
    useQuery(
        [yearsDataQueryKey],
        async () => {
            const response = await getYears();
            saveToCache(yearsDataQueryKey, response);
            return response;
        },
        {
            enabled: execute,
            suspense,
            placeholderData: () => retrieveFromCache(yearsDataQueryKey, coInnovationFundYearsListResponseParser.parse),
        }
    );

const useGetAllDetailFilters = () =>
    useQueries({
        queries: [
            {
                queryKey: [horizonsDataQueryKey],
                queryFn: async () => {
                    const response = await getHorizons();
                    saveToCache(horizonsDataQueryKey, response);
                    return response;
                },
                suspense: true,
                placeholderData: () =>
                    retrieveFromCache(horizonsDataQueryKey, coInnovationMasterDataWithIntIdentifier.parse),
            },
            {
                queryKey: [globalTeamsDataQueryKey],
                queryFn: async () => {
                    const response = await getGlobalTeams();
                    saveToCache(globalTeamsDataQueryKey, response);
                    return response;
                },
                suspense: true,
                placeholderData: () =>
                    retrieveFromCache(globalTeamsDataQueryKey, coInnovationMasterDataWithIntIdentifier.parse),
            },
            {
                queryKey: [phasesDataQueryKey],
                queryFn: async () => {
                    const response = await getPhases();
                    saveToCache(phasesDataQueryKey, response);
                    return response;
                },
                suspense: true,
                placeholderData: () =>
                    retrieveFromCache(phasesDataQueryKey, coInnovationMasterDataWithIntIdentifier.parse),
            },
            {
                queryKey: [partnersDataQueryKey],
                queryFn: async () => {
                    const response = await getPartners();
                    saveToCache(partnersDataQueryKey, response);
                    return response;
                },
                suspense: true,
                placeholderData: () => retrieveFromCache(partnersDataQueryKey, coInnovationMasterDataPartner.parse),
            },
            {
                queryKey: [teamsDataQueryKey],
                queryFn: async () => {
                    const response = await getTeams();
                    saveToCache(teamsDataQueryKey, response);
                    return response;
                },
                suspense: true,
                placeholderData: () =>
                    retrieveFromCache(teamsDataQueryKey, coInnovationMasterDataWithUuidIdentifier.parse),
            },
            {
                queryKey: [yearsDataQueryKey],
                queryFn: async () => {
                    const response = await getYears();
                    saveToCache(yearsDataQueryKey, response);
                    return response;
                },
                suspense: true,
                placeholderData: () =>
                    retrieveFromCache(yearsDataQueryKey, coInnovationFundYearsListResponseParser.parse),
            },
        ],
    });

export {
    usePartnersDataQuery,
    useTeamsDataQuery,
    useHorizonsDataQuery,
    useGlobalTeamsDataQuery,
    usePhasesDataQuery,
    useGetAllDetailFilters,
    useYearsDataQuery,
};
